import _ from 'lodash';
import moment from 'moment';
import {
  MINIMUM_ALLOWABLE_MANUFACTURING_YEAR,
  SERVER_DATE_FORMAT,
  VEHICLE_PROTECTION_TYPES,
  VEHICLE_USAGES
} from '../resources/constants';
import {
  ACCESS_RIGHTS,
  AGENCY_ACCESS_RIGHTS,
  APPROVAL_TYPE,
  COVER_PERIODS,
  DISCOUNT_RELATION_OPTIONS,
  DISCOUNT_STATUS_OPTIONS,
  ENVIRONMENTS,
  FUEL_SALES_STATUS,
  ID_TYPES_DTO,
  INSTALLMENT_TYPES,
  INSURER_ACCESS_RIGHTS,
  LEAD_SOURCE,
  PARTNER_TYPES,
  PAYMENT_CHANNELS,
  POLICY_DOCUMENT_TYPES,
  POLICY_REVIEW_STATES,
  TEAM_TYPES,
  VEHICLES_MAKE,
  VEHICLE_BODY_TYPES
} from '../resources/enums';
import {
  IClaimFormData,
  IMarketingFunnel,
  IPolicyFormData,
  IPolicyPaymentData,
  ITableFilterItem,
  IUpdateClaimDTO,
  IUpdatePolicyDTO,
  IVehicleProtectionType,
  IVehicleUsages
} from '../resources/interfaces';
import { idTypesDTO } from '../resources/types';
import { IGeneralMetrics } from '../models/generalMetrics/IGeneralMetrics';
import { ISelectDropdownItem } from '../components/form-control/SelectDropdown';
import * as Yup from 'yup';
import { HomePolicy } from '../models/homePolicy/HomePolicy';
import { Policy } from '../models/policy/Policy';
import { PaystackProps } from 'react-paystack/dist/types';

export const getNameInitials = (name: any = '') => {
  let abbr = '';
  let str = name || '';
  str = str.split(' ');
  for (let i = 0; i < str.length; i++) {
    abbr += str[i].substr(0, 1);
  }
  if (abbr) {
    if (abbr[1]) {
      return abbr[0].toUpperCase() + abbr[1].toUpperCase();
    }
    return abbr[0].toUpperCase();
  }
};

const getColorInitial = (name: any = '') => {
  if (name) {
    let abbr = '';
    let str = name || '';
    str = str.split(' ');
    for (let i = 0; i < str.length; i++) {
      abbr += str[i].substr(0, 1);
    }

    return abbr[0].toUpperCase();
  }
};

export const getCurrentQuarter = () => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;

  if (currentMonth >= 1 && currentMonth <= 3) {
    return 1;
  } else if (currentMonth >= 4 && currentMonth <= 6) {
    return 2;
  } else if (currentMonth >= 7 && currentMonth <= 9) {
    return 3;
  } else {
    return 4;
  }
};

export function getYearsList(): number[] {
  const currentYear = new Date().getFullYear();
  const yearsArray = [];

  for (let year = currentYear; year >= 2022; year--) {
    yearsArray.push(year);
  }

  return yearsArray;
}

export const getNumberOfStringCharacters = (text: string, min: number, max: number) => {
  let slicedText = '';
  if (text) {
    slicedText = text.slice(min, max);
  }
  return slicedText;
};

export const getPolicyDocumentTitleFromType = (type: POLICY_DOCUMENT_TYPES): string => {
  let title = '';
  switch (type) {
    case POLICY_DOCUMENT_TYPES.INSURANCE_STICKER:
      title = 'Sticker';
      break;

    case POLICY_DOCUMENT_TYPES.INSURANCE_CERTIFICATE:
      title = 'Insurance Certificate';
      break;

    case POLICY_DOCUMENT_TYPES.CONTRACT_SUMMARY:
      title = 'Contract Summary';
      break;

    case POLICY_DOCUMENT_TYPES.PAYMENT_AGREEMENT:
      title = 'Payment Agreement';
      break;
    case POLICY_DOCUMENT_TYPES.PAYMENT_LOAN_AGREEMENT:
      title = 'Payment Loan Agreement';
      break;
    case POLICY_DOCUMENT_TYPES.LOAN_AGREEMENT:
      title = 'Loan Agreement';
      break;

    case POLICY_DOCUMENT_TYPES.ROADWORTHY:
      title = 'Roadworthy';
      break;

    case POLICY_DOCUMENT_TYPES.ID_CARD:
      title = "Owner's ID";
      break;

    case POLICY_DOCUMENT_TYPES.PASSPORT_PHOTO:
      title = "Owner's Photograph";
      break;

    case POLICY_DOCUMENT_TYPES.VEHICLE_PHOTO_FRONT:
      title = "Vehicle's Front Photograph'";
      break;

    case POLICY_DOCUMENT_TYPES.VEHICLE_PHOTO_LEFT:
      title = "Vehicle's Left Photograph";
      break;

    case POLICY_DOCUMENT_TYPES.VEHICLE_PHOTO_BACK:
      title = "Vehicle's Back Photograph";
      break;

    case POLICY_DOCUMENT_TYPES.VEHICLE_PHOTO_RIGHT:
      title = "Vehicle's Right Photograph";
      break;

    case POLICY_DOCUMENT_TYPES.DRIVER_LICENSE_BACK:
      title = "Driver's License Back";
      break;

    case POLICY_DOCUMENT_TYPES.DRIVER_LICENSE_FRONT:
      title = "Driver's License Front";
      break;

    case POLICY_DOCUMENT_TYPES.RENEWAL_NOTICE:
      title = 'Renewal Notice';
      break;
    case POLICY_DOCUMENT_TYPES.POLICY_SCHEDULE:
      title = 'Policy Schedule';
      break;
    case POLICY_DOCUMENT_TYPES.POLICY:
      title = 'Policy Document';
      break;
  }

  return title;
};

export function getVehicleUsageFromKey(key: string): IVehicleUsages | null {
  if (!_.isEmpty(VEHICLE_USAGES)) {
    return VEHICLE_USAGES?.[key as keyof IVehicleUsages];
  }

  return null;
}

export function getVehicleProtectionFromKey(
  key: string | IVehicleProtectionType
): IVehicleProtectionType | null {
  if (!_.isEmpty(VEHICLE_PROTECTION_TYPES)) {
    return VEHICLE_PROTECTION_TYPES?.[key as keyof IVehicleProtectionType] ?? { title: key };
  }

  return null;
}

export function getIdTypeFromValue(idType: idTypesDTO | string): string | undefined {
  switch (idType) {
    case 'PASSPORT':
      return 'Passport';
    case 'NATIONAL_ID':
      return 'National ID';
    case 'VOTER_ID':
      return "Voter's ID";
    case 'DRIVER_LICENSE':
      return "Driver's Lincense";
    case 'SOCIAL_SECURITY':
      return 'Social Security';
    default:
      return undefined;
  }
}

export function getIdTypeFromString(idType: string) {
  switch (idType?.toLowerCase()) {
    case 'passport':
      return ID_TYPES_DTO.PASSPORT;
    case 'national id':
      return ID_TYPES_DTO.NATIONAL_ID;
    case "voter's id":
      return ID_TYPES_DTO.VOTER_ID;
    case "driver's lincense":
      return ID_TYPES_DTO.DRIVER_LICENSE;
    case 'social security':
      return ID_TYPES_DTO.SOCIAL_SECURITY;
    default:
      return undefined;
  }
}

export const getPolicyReviewStatus = (status: POLICY_REVIEW_STATES | string | boolean): string => {
  switch (status) {
    case POLICY_REVIEW_STATES.APPROVED:
    case true:
      return 'Approved';

    case POLICY_REVIEW_STATES.CANCELLED:
      return 'Cancelled';

    case POLICY_REVIEW_STATES.ON_HOLD:
      return 'On Hold';

    case POLICY_REVIEW_STATES.IN_APPROVAL:
      return 'In Approval';

    case POLICY_REVIEW_STATES.DECLINED:
      return 'Declined';

    case POLICY_REVIEW_STATES.NEW:
    case false:
      return 'New';

    case POLICY_REVIEW_STATES.IN_REVIEW:
      return 'In Review';

    case POLICY_REVIEW_STATES.ARCHIVED:
      return 'Archived';

    case POLICY_REVIEW_STATES.DUE_LATER:
      return 'Due Later';

    case POLICY_REVIEW_STATES.EXPIRED:
      return 'Expired';
  }

  return status;
};

export const getInstallmentTypeFromKey = (installment: INSTALLMENT_TYPES | string): string => {
  switch (installment) {
    case INSTALLMENT_TYPES.FULL_PAYMENT:
      return 'Annually';

    case INSTALLMENT_TYPES.TWELVE_MONTHS:
    case INSTALLMENT_TYPES.NINE_MONTHS:
    case INSTALLMENT_TYPES.SIX_MONTHS:
    case INSTALLMENT_TYPES.THREE_MONTHS:
    case INSTALLMENT_TYPES.MONTHLY:
      return 'Monthly';

    case INSTALLMENT_TYPES.TWELVE_WEEKS:
    case INSTALLMENT_TYPES.NINE_WEEKS:
    case INSTALLMENT_TYPES.TEN_WEEKS:
    case INSTALLMENT_TYPES.WEEKLY:
      return 'Weekly';

    case INSTALLMENT_TYPES.ONE_HUNDRED_AND_TWENTY_DAYS:
    case INSTALLMENT_TYPES.NINETY_DAYS:
    case INSTALLMENT_TYPES.SEVENTY_DAYS:
    case INSTALLMENT_TYPES.SIXTY_FIVE_DAYS:
    case INSTALLMENT_TYPES.SIXTY_DAYS:
    case INSTALLMENT_TYPES.FIFTY_FIVE_DAYS:
    case INSTALLMENT_TYPES.FORTY_DAYS:
    case INSTALLMENT_TYPES.THREE_DAYS_TEST:
    case INSTALLMENT_TYPES.DAILY:
      return 'Daily';

    default:
      return '';
  }
};

export const getInstallmentPeriodFromKey = (
  installment: INSTALLMENT_TYPES | string,
  policy?: Policy
): string => {
  switch (installment) {
    case INSTALLMENT_TYPES.FULL_PAYMENT:
      return 'FULL PAYMENT';

    case INSTALLMENT_TYPES.TWELVE_MONTHS:
      return 'TWELVE MONTHS';

    case INSTALLMENT_TYPES.NINE_MONTHS:
      return 'NINE MONTHS';

    case INSTALLMENT_TYPES.SIX_MONTHS:
      return 'SIX MONTHS';

    case INSTALLMENT_TYPES.THREE_MONTHS:
      return 'THREE MONTHS';

    case INSTALLMENT_TYPES.TWELVE_WEEKS:
      return 'TWELVE WEEKS';

    case INSTALLMENT_TYPES.NINE_WEEKS:
      return 'NINE WEEKS';

    case INSTALLMENT_TYPES.TEN_WEEKS:
      return 'TEN WEEKS';

    case INSTALLMENT_TYPES.ONE_HUNDRED_AND_TWENTY_DAYS:
      return 'ONE HUNDRED AND TWENTY DAYS';

    case INSTALLMENT_TYPES.NINETY_DAYS:
      return 'NINETY DAYS';

    case INSTALLMENT_TYPES.SEVENTY_DAYS:
      return 'SEVENTY DAYS';

    case INSTALLMENT_TYPES.SIXTY_FIVE_DAYS:
      return 'SIXTY FIVE DAYS';

    case INSTALLMENT_TYPES.SIXTY_DAYS:
      return 'SIXTY DAYS';

    case INSTALLMENT_TYPES.FORTY_DAYS:
      return 'FORTY DAYS';

    case INSTALLMENT_TYPES.THREE_DAYS_TEST:
      return 'THREE DAYS TEST';

    case INSTALLMENT_TYPES.MONTHLY:
      return 'MONTHLY';

    case INSTALLMENT_TYPES.WEEKLY:
      return 'WEEKLY';

    case INSTALLMENT_TYPES.DAILY:
      return 'DAILY';

    default:
      if (policy?.quote?.period) {
        return `${policy?.quote?.period} ${
          policy?.isDailyInstallment() ? 'Days' : policy?.isWeeklyInstallment() ? 'Weeks' : 'Months'
        }`;
      } else {
        return '';
      }
  }
};

export const getInstallmentPeriodPrefix = (installmentPeriod?: string) => {
  let prefix = '';

  if (installmentPeriod?.includes('_MONTHS')) prefix = 'Monthly';
  else if (installmentPeriod?.includes('_WEEKLY')) prefix = 'Weekly';
  else prefix = 'Daily';

  return prefix;
};

export const getNumOfInstallmentsFromInstallmentPeriod = (
  installment: INSTALLMENT_TYPES | string
): number => {
  switch (installment) {
    case INSTALLMENT_TYPES.FULL_PAYMENT:
      return 1;

    case INSTALLMENT_TYPES.TWELVE_MONTHS:
      return 11;

    case INSTALLMENT_TYPES.NINE_MONTHS:
      return 8;

    case INSTALLMENT_TYPES.SIX_MONTHS:
      return 5;

    case INSTALLMENT_TYPES.THREE_MONTHS:
      return 2;

    case INSTALLMENT_TYPES.TWELVE_WEEKS:
      return 11;

    case INSTALLMENT_TYPES.NINE_WEEKS:
      return 9;

    case INSTALLMENT_TYPES.TEN_WEEKS:
      return 10;

    case INSTALLMENT_TYPES.ONE_HUNDRED_AND_TWENTY_DAYS:
      return 125;

    case INSTALLMENT_TYPES.NINETY_DAYS:
      return 90;

    case INSTALLMENT_TYPES.SEVENTY_DAYS:
      return 70;

    case INSTALLMENT_TYPES.SIXTY_FIVE_DAYS:
      return 65;

    case INSTALLMENT_TYPES.SIXTY_DAYS:
      return 60;

    case INSTALLMENT_TYPES.FIFTY_FIVE_DAYS:
      return 55;

    case INSTALLMENT_TYPES.FORTY_DAYS:
      return 40;

    case INSTALLMENT_TYPES.THREE_DAYS_TEST:
      return 3;

    default:
      return 0;
  }
};

export const getFormattedLeadSource = (leadSource: LEAD_SOURCE | string) => {
  switch (leadSource) {
    case LEAD_SOURCE.ASEMPA_FM:
      return 'ASEMPA FM';
    case LEAD_SOURCE.CITIFM_ONLINE:
      return 'CITI FM ONLINE';
    case LEAD_SOURCE.CITI_FM:
      return 'CITI FM';
    case LEAD_SOURCE.CUSTOMER_REFERRAL:
      return 'CUSTOMER REFERRAL';
    case LEAD_SOURCE.FACEBOOK:
      return 'FACEBOOK';
    case LEAD_SOURCE.GHANA_WEB:
      return 'GHANA WEB';
    case LEAD_SOURCE.GOOGLE_BUSINESS:
      return 'GOOGLE BUSINESS';
    case LEAD_SOURCE.INSTAGRAM:
      return 'INSTAGRAM';
    case LEAD_SOURCE.JOY_FM:
      return 'JOY FM';
    case LEAD_SOURCE.LINKEDIN:
      return 'LINKEDIN';
    case LEAD_SOURCE.MYJOY_ONLINE:
      return 'MYJOY ONLINE';
    case LEAD_SOURCE.OTHER:
      return 'OTHER';
    case LEAD_SOURCE.TEAM_DIRECT:
      return 'TEAM DIRECT';
    case LEAD_SOURCE.DIRECT_SALES:
      return 'DIRECT SALES';
    case LEAD_SOURCE.TIKTOK:
      return 'TIKTOK';
    case LEAD_SOURCE.BOLT_APP:
      return 'BOLT APP';
    case LEAD_SOURCE.WEBSITES_OR_BLOGS:
      return 'WEBSITES OR BLOGS';
    case LEAD_SOURCE.SOCIAL_MEDIA:
      return 'SOCIAL MEDIA';
    case LEAD_SOURCE.WHATSAPP:
      return 'WHATSAPP';
    case LEAD_SOURCE.YANGO_APP:
      return 'YANGO_APP';
  }
  return leadSource;
};

export const getFormattedLeadSourceFromEnum = (leadSource: LEAD_SOURCE | string): string => {
  switch (leadSource) {
    case 'ASEMPA FM':
      return LEAD_SOURCE.ASEMPA_FM;
    case 'CITI FM ONLINE':
      return LEAD_SOURCE.CITIFM_ONLINE;
    case 'CITI FM':
      return LEAD_SOURCE.CITI_FM;
    case 'CUSTOMER REFERRAL':
      return LEAD_SOURCE.CUSTOMER_REFERRAL;
    case 'FACEBOOK':
      return LEAD_SOURCE.FACEBOOK;
    case 'GHANA WEB':
      return LEAD_SOURCE.GHANA_WEB;
    case 'GOOGLE BUSINESS':
      return LEAD_SOURCE.GOOGLE_BUSINESS;
    case 'INSTAGRAM':
      return LEAD_SOURCE.INSTAGRAM;
    case 'JOY FM':
      return LEAD_SOURCE.JOY_FM;
    case 'LINKEDIN':
      return LEAD_SOURCE.LINKEDIN;
    case 'MYJOY ONLINE':
      return LEAD_SOURCE.MYJOY_ONLINE;
    case 'OTHER':
      return LEAD_SOURCE.OTHER;
    case 'TEAM DIRECT':
      return LEAD_SOURCE.TEAM_DIRECT;
    case 'TIKTOK':
      return LEAD_SOURCE.TIKTOK;
    case 'BOLT APP':
      return LEAD_SOURCE.BOLT_APP;
    case 'DIRECT SALES':
      return LEAD_SOURCE.DIRECT_SALES;
  }
  return leadSource;
};

export function removeUnderscoresAndDashes(inputString: string) {
  return inputString.replace(/[_-]/g, '');
}

export function removeUnderscoresAndCapitalize(str: string): string {
  const words: string[] = str.split('_');

  const capitalizedWords: string[] = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  );

  return capitalizedWords.join(' ');
}

export function replaceSpacesWithUnderscores(inputString: string) {
  return inputString.replace(/[_\s-]/g, '_');
}

export function putDot(number: number) {
  return (number / 100).toFixed(2);
}

export function numberWithCommas(numb: string | number): string {
  if (numb) {
    const str = numb.toString().split('.');
    str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return str.join('.');
  }
  return String(0.0);
}

export function sentenceCase(str: string) {
  let _tmp = str.toLowerCase();
  let result = _tmp
    .replace(/[a-z]/i, function (letter) {
      return letter.toUpperCase();
    })
    .trim();

  return result;
}

export const isThirdPartyProtection = (protectionType: string): boolean => {
  return protectionType === 'Third Party';
};

export const isHailingService = (vehicleUse: string | undefined): boolean => {
  return vehicleUse === 'HAILING_SERVICE';
};

export const isCommercial = (vehicleUse: string | undefined | null): boolean => {
  return (
    vehicleUse?.toLowerCase() === VEHICLE_USAGES?.HAILING_SERVICE?.title?.toLowerCase() ||
    vehicleUse?.toLowerCase() === VEHICLE_USAGES?.TAXI?.title?.toLowerCase() ||
    vehicleUse?.toLowerCase() === VEHICLE_USAGES?.HIRING_CAR?.title?.toLowerCase()
  );
};

export const getFormattedCoverPeriod = (period: COVER_PERIODS | undefined) => {
  switch (period) {
    case 12:
      return 'One Year';

    case 9:
      return 'Nine Months';

    case 6:
      return 'Six Months';

    case 3:
      return 'Three Months';
  }

  return period;
};

export const policyFormDataToUpdatePolicyDTO = (
  data: IPolicyFormData
): Partial<IUpdatePolicyDTO> => {
  return {
    bodyType: getVehicleBodyTypeFromString(data.vehicleType ?? ''),
    cc: data.cc ?? 0,
    chasisNumber: data.chassisNumber ?? '',
    color: data.vehicleColor ?? '',
    emailAddress: data.email,
    firstname: data.firstName ?? '',
    phone: getFormattedPhoneNumber(data.phoneNumber) ?? '',
    otherPhone: getFormattedPhoneNumber(data.otherPhone) ?? '',
    idExpiry: data.idExpiryDate ? moment(data.idExpiryDate).format(SERVER_DATE_FORMAT) : '',
    idNumber: data.idNumber ?? '',
    idType: data.idType,
    lastname: data.lastName ?? '',
    make: data.vehicleMake,
    manufacturedYear: data.manufacturedYear,
    maxSeat: data.numOfPassenger,
    model: data.vehicleModel,
    registrationNumber: data.registrationNumber,
    registrationYear: data.registrationYear,
    dateOfBirth: moment(data.dateOfBirth).format(SERVER_DATE_FORMAT),
    occupation: data.occupation,
    owner: data.registeredOwner,
    vehicleRegion: data.vehicleRegion,
    mainDriver: data.vehicleMainDriver,
    address: data.address,
    employerName: data.employerName,
    employerAddress: data.employerAddress,
    employerPhone: getFormattedPhoneNumber(data.employerPhoneNumber ?? ''),
    monthlyIncome: data.monthlyIncome,
    declinedInsuranceDetails: data.declinedByOtherInsurer,
    previousInsuranceClaims: data.issuedClaimDetails,
    alterationDetails: data.alterationDetails,
    vehicleAltered: data.vehicleAltered,
    goodRepairState: data.goodRepairState,
    repairState: data.repairState,
    driverComplications: data.diseaseOrComplications,
    insurerId: data.insurer,
    refereeName: data.refereeName,
    refereePhone: getFormattedPhoneNumber(data.refereePhone ?? ''),
    refereeYearsKnown: data.refereeYearsKnown,
    refereeRelationship: data.refereeRelationship,
    hasInterestedParties: data.hasInterestedParties,
    interestedParties: data.interestedParties,
    communityId: data.community ? data.community : null,
    startsOn: data.startsOn,
    hasInstalledAccessories: data.hasInterestedParties,
    installedAccessoriesDetails: data.installedAccessoriesDetails,
    installedAccessoriesValue: data.installedAccessoriesValue,
    natureOfInterest: data.natureOfInterestInVehicle,
    ownerAddress: data.ownerAddress,
    ownerName: data.ownerName,
    ownerPhone: getFormattedPhoneNumber(data.ownerPhone ?? ''),
    vehicleInGoodState: data.goodRepairState,
    hasDeclinedClaims: data.previouslyDeclined,
    hasPreviousClaims: data.previouslyDeclined,
    rideSharePeriod: data.rideSharePeriod,
    liability: data.liability,
    liabilityDebt: data.liabilityDebt,
    liabilityModeOfPayment: data.liabilityModeOfPayment,
    parkId: data.parkId
  };
};

export const claimFormDataToUpdateClaimDTO = (data: IClaimFormData): Partial<IUpdateClaimDTO> => {
  return {};
};

export function getFormattedPhoneNumber(phone: string): string {
  if (!phone) {
    return '';
  }

  let formattedPhone = phone?.trim();

  if (formattedPhone.startsWith('0')) {
    formattedPhone = '233' + formattedPhone.slice(1);
  }

  return formattedPhone;
}

export function getPhoneNumberFromShortCodeFormat(phone: string): string {
  if (!phone || (!phone.startsWith('233') && !phone.startsWith('+233'))) {
    return phone?.trim();
  }

  let formattedPhone = phone?.trim();

  if (formattedPhone.startsWith('+')) {
    formattedPhone = formattedPhone.slice(1);
  }

  return '0' + formattedPhone.slice(3).trim();
}

export function getVehicleBodyTypeFromString(type: string): VEHICLE_BODY_TYPES | null {
  switch (type?.toLowerCase()) {
    case 'suv':
      return VEHICLE_BODY_TYPES.SUV;

    case 'convertible':
      return VEHICLE_BODY_TYPES.CONVERTIBLE;

    case 'hatchback':
      return VEHICLE_BODY_TYPES.HATCHBACK;

    case 'station wagon':
      return VEHICLE_BODY_TYPES.STATION_WAGON;

    case 'coupe':
      return VEHICLE_BODY_TYPES.COUPE;

    case 'van':
      return VEHICLE_BODY_TYPES.VAN;

    case 'pickup':
      return VEHICLE_BODY_TYPES.PICKUP;

    case 'saloon/sedan':
      return VEHICLE_BODY_TYPES.SALOON_SEDAN;

    case 'mini/small bus':
      return VEHICLE_BODY_TYPES.MINI_OR_SMALL_BUS;

    case 'maxi/big bus':
      return VEHICLE_BODY_TYPES.MAXI_OR_BIG_BUS;

    case 'sport car':
      return VEHICLE_BODY_TYPES.SPORT_CAR;

    case 'articulated truck':
      return VEHICLE_BODY_TYPES.ARTICULATED_TRUCK;

    case 'motorcycle':
      return VEHICLE_BODY_TYPES.MOTOR_BIKE;
  }

  return null;
}

export function getListFromEnum(data: any): any[] {
  const list: any[] = [];

  Object.keys(data).forEach((key) => {
    list.push(sentenceCase(data[key]));
  });

  return list;
}

export function getSelectOptionsFromEnum(data: any, addDefault?: boolean): ISelectDropdownItem[] {
  const list: ISelectDropdownItem[] = [];
  if (addDefault) {
    list.push({
      name: 'All',
      value: 'All'
    });
  }

  Object.keys(data).forEach((key) => {
    const stringsArray = data[key].split('_');
    let newName = '';

    stringsArray.forEach((value: string) => {
      newName += ` ${sentenceCase(value)}`;
    });
    list.push({
      name: newName,
      value: data[key]
    });
  });

  return list;
}

export function getListFromRecord(data: any, objectKey: string): any[] {
  const list: any[] = [];

  Object.keys(data).forEach((key) => {
    list.push(sentenceCase(data[key][objectKey]));
  });

  return list;
}

export const getSelectableManufacturingYears = () => {
  let thisYear = new Date().getFullYear();
  const yearsList = [new Date().getFullYear()];

  while (thisYear > MINIMUM_ALLOWABLE_MANUFACTURING_YEAR) {
    yearsList.push(thisYear - 1);

    thisYear--;
  }
  return yearsList;
};

export default {
  getNameInitials,
  getColorInitial
};

export const getDiscountRelationsOptionsForTable = (
  relation: DISCOUNT_RELATION_OPTIONS | string
): string => {
  switch (relation) {
    case 'INITIAL_DEPOSIT':
      return DISCOUNT_RELATION_OPTIONS.INITIAL_DEPOSIT;

    case 'MONTHLY_INSTALLMENTS':
      return DISCOUNT_RELATION_OPTIONS.MONTHLY_INSTALLMENTS;

    case 'ONE_TIME':
      return DISCOUNT_RELATION_OPTIONS.ONE_TIME;
  }

  return '';
};

export const getDiscountRelationsOptions = (
  relation: DISCOUNT_RELATION_OPTIONS | string
): string => {
  switch (relation) {
    case DISCOUNT_RELATION_OPTIONS.INITIAL_DEPOSIT:
      return 'INITIAL_DEPOSIT';

    case DISCOUNT_RELATION_OPTIONS.MONTHLY_INSTALLMENTS:
      return 'MONTHLY_INSTALLMENTS';

    case DISCOUNT_RELATION_OPTIONS.ONE_TIME:
      return 'ONE_TIME';
  }

  return '';
};

export const getFormattedPaymentChannel = (channel: PAYMENT_CHANNELS | string): string => {
  switch (channel) {
    case PAYMENT_CHANNELS.MOBILE_MONEY:
      return 'MOBILE MONEY';

    case PAYMENT_CHANNELS.CARD:
      return 'CARD';

    case PAYMENT_CHANNELS.CASH:
      return 'CASH';

    case PAYMENT_CHANNELS.BANK_TRANSFER:
      return 'BANK TRANSFER';

    case PAYMENT_CHANNELS.CHEQUE_DEPOSIT:
      return 'CHEQUE DEPOSIT';

    case PAYMENT_CHANNELS.VOUCHER:
      return 'VOUCHER';
  }

  return '';
};

export const getValueFromFormattedPaymentChannel = (relation: string): string => {
  switch (relation) {
    case 'MOBILE MONEY':
      return PAYMENT_CHANNELS.MOBILE_MONEY;

    case 'CARD':
      return PAYMENT_CHANNELS.CARD;

    case 'CASH':
      return PAYMENT_CHANNELS.CASH;

    case 'BANK TRANSFER':
      return PAYMENT_CHANNELS.BANK_TRANSFER;

    case 'CHEQUE DEPOSIT':
      return PAYMENT_CHANNELS.CHEQUE_DEPOSIT;

    case 'VOUCHER':
      return PAYMENT_CHANNELS.VOUCHER;
  }
  return '';
};

export const getFormattedAccessRight = (accessRight?: string): string => {
  switch (accessRight) {
    case ACCESS_RIGHTS.ADMINISTRATOR:
      return 'Administrator';
    case INSURER_ACCESS_RIGHTS.INSURER_ADMIN:
      return 'Insurer Super Admin';
    case INSURER_ACCESS_RIGHTS.INSURER_AGENT:
      return 'Insurer Agent';
    case INSURER_ACCESS_RIGHTS.INSURER_TECH_ADMIN:
      return 'Insurer Tech Admin';
    case INSURER_ACCESS_RIGHTS.INSURER_POLICY_ADMIN:
      return 'Insurer Policy Admin ';
    case INSURER_ACCESS_RIGHTS.INSURER_FINANCE_ADMIN:
      return 'Insurer Finance Admin';
    case INSURER_ACCESS_RIGHTS.INSURER_CLAIM_ADMIN:
      return 'Insurer Claim Admin';
    case ACCESS_RIGHTS.INSURER_MARKETING_ADMIN:
      return 'Insurer Marketing Admin';
    case ACCESS_RIGHTS.INSURER_UNDERWRITER:
      return 'Insurer Underwriter';
    case ACCESS_RIGHTS.AGENCY_ADMIN:
      return 'Agency Admin';
    case ACCESS_RIGHTS.AGENT:
      return 'Agent';
    case ACCESS_RIGHTS.REVIEWER:
      return 'Reviewer';
    case ACCESS_RIGHTS.SUPER_ADMINISTRATOR:
      return 'Super Administrator';
    case ACCESS_RIGHTS.RIDE_SHARING_ADMIN:
      return 'Ride Sharing Admin';
    case ACCESS_RIGHTS.RIDE_SHARING_AGENT:
      return 'Ride Sharing Agent';
    case ACCESS_RIGHTS.FLEET_ADMIN:
      return 'Fleet Admin';
    case ACCESS_RIGHTS.FLEET_MANAGER:
      return 'Fleet Manager';
    case ACCESS_RIGHTS.FUEL_ADMIN:
      return 'Fuel Admin';
    case ACCESS_RIGHTS.FUEL_MANAGER:
      return 'Fuel Manager';
    case ACCESS_RIGHTS.FUEL_VENDOR:
      return 'Fuel Vendor';
  }

  return '';
};

export const getFormattedTeamTypeFromKey = (type?: TEAM_TYPES): string => {
  switch (type) {
    case TEAM_TYPES.INTERNAL:
      return 'INTERNAL';
    case TEAM_TYPES.AGENCY:
      return 'AGENCY';
    case TEAM_TYPES.RIDE_SHARING:
      return 'RIDE SHARING';
    case TEAM_TYPES.REPAIR_CENTER:
      return 'REPAIR CENTER';
    case TEAM_TYPES.AFFILIATE:
      return 'AFFILIATE';
    case TEAM_TYPES.INSURER:
      return 'INSURER';
    case TEAM_TYPES.FLEET:
      return 'FLEET';
    case TEAM_TYPES.FUEL_PROVIDER:
      return 'FUEL PROVIDER';
    case TEAM_TYPES.OTHER:
      return 'OTHER';
  }

  return '';
};
export const getFormattedPartnerTypeFromKey = (type?: PARTNER_TYPES): string => {
  switch (type) {
    case PARTNER_TYPES.RIDE_SHARING:
      return 'RIDE SHARING';
    case PARTNER_TYPES.REPAIR_CENTER:
      return 'REPAIR CENTER';
    case PARTNER_TYPES.AFFILIATE:
      return 'AFFILIATE';
    case PARTNER_TYPES.INSURER:
      return 'INSURER';
    case PARTNER_TYPES.FLEET:
      return 'FLEET';
    case PARTNER_TYPES.FUEL_PROVIDER:
      return 'FUEL PROVIDER';
    case PARTNER_TYPES.OTHER:
      return 'OTHER';
  }
  return '';
};

export const getAccessRightKeyFromString = (accessRight: string) => {
  switch (accessRight) {
    case 'Administrator':
      return ACCESS_RIGHTS.ADMINISTRATOR;
    case 'Ride Sharing Admin':
      return ACCESS_RIGHTS.RIDE_SHARING_ADMIN;
    case 'Ride Sharing Agent':
      return ACCESS_RIGHTS.RIDE_SHARING_AGENT;
    case 'Insurer Admin':
      return ACCESS_RIGHTS.INSURER_ADMIN;
    case 'Insurer Agent':
      return ACCESS_RIGHTS.INSURER_AGENT;
    case 'Insurer Claim Admin':
      return ACCESS_RIGHTS.INSURER_CLAIM_ADMIN;
    case 'Insurer Finance Admin':
      return ACCESS_RIGHTS.INSURER_FINANCE_ADMIN;
    case 'Insurer Tech Admin':
      return ACCESS_RIGHTS.INSURER_TECH_ADMIN;
    case 'Insurer Policy Admin':
      return ACCESS_RIGHTS.INSURER_POLICY_ADMIN;
    case 'Agency Admin':
      return ACCESS_RIGHTS.AGENCY_ADMIN;
    case 'Agent':
      return ACCESS_RIGHTS.AGENT;
    case 'Reviewer':
      return ACCESS_RIGHTS.REVIEWER;
    case 'Super Administrator':
      return ACCESS_RIGHTS.SUPER_ADMINISTRATOR;
    case 'Fleet Admin':
      return ACCESS_RIGHTS.FLEET_ADMIN;
    case 'Fleet Manager':
      return ACCESS_RIGHTS.FLEET_MANAGER;
  }

  return '';
};

export function getImageExtension(imageFile: any) {
  if (imageFile) {
    let imageName = imageFile.name;
    let dotIndex = imageName.lastIndexOf('.');
    return imageName.substring(dotIndex + 1);
  }
  return 'jpeg';
}

export function blobToFile(compressedFile: any, originalFile: any) {
  return new File([compressedFile], originalFile.name);
}

export const getConvertedAmountFromPesewas = (value: number, numOfDecimalPlaces = 2): string => {
  return value ? (value / 100).toFixed(numOfDecimalPlaces) : '0.00';
};

export const getMarkettingFunnelFromGeneralMetrics = (
  metrics: IGeneralMetrics
): IMarketingFunnel => {
  return {
    allTraffic: metrics.traffic_activity ?? 0,
    allLeads: metrics.leads,
    qualifiedLeads: metrics.leads_status?.cold,
    closedSales: metrics.leads_status?.closed,
    paidSales: metrics.leads_status?.paid
  };
};

export function getPaystackKey() {
  return process.env.REACT_APP_PAYSTACK_KEY;
}

export const isDevelopment = () => {
  return process.env.REACT_APP_ENVIRONMENT === ENVIRONMENTS.DEVELOPMENT;
};

export const isTest = () => {
  return process.env.REACT_APP_ENVIRONMENT === ENVIRONMENTS.TEST;
};

export const isDev = () => {
  return process.env.REACT_APP_ENVIRONMENT === ENVIRONMENTS.DEV;
};

export const isProduction = () => {
  return process.env.REACT_APP_ENVIRONMENT === ENVIRONMENTS.PRODUCTION;
};

export const getEnvironment = () => {
  return process.env.REACT_APP_ENVIRONMENT;
};

export function getFunnelStepGrowth(num: number, denum: number): number {
  if (denum <= 0 && num === 0) {
    return 0;
  }

  if (denum <= 0 && num > 0) {
    return 100;
  }
  const result = (num / denum) * 100;
  return typeof result === 'number' && result >= 0 ? Number(String(result.toFixed(1))) : 0;
}

export function getChangeGrowth(currentValue: number, prevValue: number): number {
  const result = ((currentValue - prevValue) / currentValue) * 100;
  return typeof result === 'number' && result >= 0 ? Number(String(result.toFixed(1))) : 0;
}

export const getIdPlaceholder = (idType: string | undefined): string => {
  let placeholder = '';
  switch (idType) {
    case 'Passport':
      placeholder = 'Eg: GXXXXXXX';
      break;
    case "Voter's ID":
      placeholder = 'Eg: XXXXXXXXXX';
      break;
    case "Driver's Lincense":
      placeholder = 'Eg: ABC-XXXXXXXX-XXXXX';
      break;

    case 'National ID':
      placeholder = 'Eg: GHA-XXXXXXXXX-X';
      break;
    default:
      placeholder = 'Eg: GHA-XXXXXXXXX-X';
      break;
  }

  return placeholder;
};

export const IntlPhoneInputValidation = {
  phone: Yup.string()
    .when([], (schema: any) => {
      return schema
        .test({
          test: (value: any) => {
            if (value && value.startsWith('+233')) {
              return value.length === 13;
            }
            return true;
          },
          message: 'Phone number must be 10 digits long'
        })
        .required('Phone number is required')
        .matches(/^\+\d+$/, 'Must be only digits')
        .min(7, 'Phone number cannot be less than 7 digits')
        .max(16, 'Phone number cannot be more than 16 digits');
    })
    .trim()
};

export const PhoneInputValidation = {
  phone: Yup.string()
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(10, 'Must be exactly 10 digits')
    .max(10, 'Must be exactly 10 digits')
    .trim()
    .required('Phone number is required.')
};

export const getYoutubeVideoIdFromUrl = (youtubeUrl: string): string => {
  const videoURL = youtubeUrl.split('/');
  let videoId = '';
  videoURL.map((part: any, index: any) => {
    if (part.includes('watch')) {
      videoId = part.split('=')[1];
    } else {
      if (index === 3) {
        videoId = part;
      }
    }
  });

  return videoId;
};

export function capitalizeSentence(sentence: string) {
  // Split the sentence into words using both space and underscore as separators
  const words = sentence.split(/[ _]/);

  // Capitalize the first letter of each word and join them back into a sentence
  const capitalizedWords = words.map((word: string) => {
    if (word.length > 0) {
      return word[0].toUpperCase() + word.slice(1).toLowerCase();
    }
    return '';
  });

  // Join the capitalized words into a sentence
  const capitalizedSentence = capitalizedWords.join(' ');

  return capitalizedSentence;
}

export const getInsurerUserTypesList = (data: any) => {
  const list: ISelectDropdownItem[] = [];

  Object.keys(ACCESS_RIGHTS)
    ?.filter((key) => key.toLowerCase().includes('insurer'))
    .forEach((key) => {
      list.push({
        name: capitalizeSentence(data[key]?.split('_')?.join(' ')),
        value: key
      });
    });

  return list.filter(
    (listItem: ISelectDropdownItem) => listItem.value !== ACCESS_RIGHTS.INSURER_ADMIN
  );
};

export function getClientWebsiteUrl(): string {
  return process.env.REACT_APP_CLIENT_WEBSITE ?? '';
}

export const getPaymentDataFromPolicy = (
  policy: Policy | HomePolicy,
  phone?: string,
  network?: string
): IPolicyPaymentData => {
  return {
    accountNumber: phone ?? policy?.applicant?.phoneNumber ?? '',
    initialAmount: policy.quote?.quotePlan?.initialDeposit ?? 0,
    network: network ?? '',
    policyNumber: policy.ref,
    recurringAmount: policy.quote?.quotePlan?.monthlyInstallment ?? 0,
    discountCode: '',
    isFullPayment: policy.isFullPayment(),
    isAutoPolicy: policy.isAutoPolicy(),
    phone: phone ?? policy?.applicant?.phoneNumber ?? ''
  };
};

export const getBooleanSelectDropdownList = () => {
  return [
    {
      name: 'No',
      value: 'no'
    },
    {
      name: 'Yes',
      value: 'yes'
    }
  ];
};

export const getPaystackConfig = (policy?: Policy | HomePolicy): PaystackProps => {
  const amount = policy?.isFullPayment()
    ? (policy?.quote?.quotePlan?.outRightPremium ?? 0).toFixed(2)
    : (policy?.quote?.quotePlan?.initialDeposit ?? 0).toFixed(2);

  return {
    reference: new Date().getTime().toString(),
    email: 'support@brolly.africa',
    amount: policy ? Number(amount) * 100 : 0,
    currency: 'GHS',
    publicKey: getPaystackKey() ?? '',
    metadata: {
      policyNumber: policy?.ref,
      custom_fields: [
        {
          display_name: 'Policy Number',
          variable_name: 'policyNumber',
          value: policy?.ref
        }
      ]
    }
  };
};

export async function copyTextToClipboard(text: string) {
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand('copy', true, text);
  }
}

export const copyToClipboard = async (text: string) => {
  await navigator.clipboard.writeText(text);
};

export function hasSuccessfulPayment(payments: any) {
  for (let i = 0; i < payments.length; i++) {
    for (let key in payments[i]) {
      if (key === 'paymentStatus' && payments[i][key] === 'SUCCESS') {
        return true;
      }
    }
  }
  return false;
}

export function getQuarter(date = new Date()) {
  return Math.floor(date.getMonth() / 3 + 1);
}

export function getPathAndParamsStringFromUrl(url: string) {
  try {
    const parsedUrl = new URL(url);
    const path = parsedUrl.pathname;
    const paramsString = parsedUrl.searchParams.toString();
    return path + (paramsString ? '?' + paramsString : '');
  } catch (error) {
    console.error('Error parsing URL:', error);
    return null;
  }
}

export const getFormattedSalesStatusFromKey = (status: FUEL_SALES_STATUS): string => {
  switch (status) {
    case FUEL_SALES_STATUS.NEW:
      return 'NEW';

    case FUEL_SALES_STATUS.FAILED:
      return 'FAILED';

    case FUEL_SALES_STATUS.CLOSED:
      return 'CLOSED';

    case FUEL_SALES_STATUS.PENDING_CLOSE:
      return 'PENDING CLOSE';

    case FUEL_SALES_STATUS.PENDING_SETTLEMENT:
      return 'PENDING SETTLEMENT';

    case FUEL_SALES_STATUS.PAID:
      return 'PAID';

    case FUEL_SALES_STATUS.PENDING_PAYMENT:
      return 'PENDING PAYMENT';

    case FUEL_SALES_STATUS.COMPLETED:
      return 'COMPLETED';
  }
  return '';
};

export const getStatusColor = (status: string) => {
  let textColor = '';
  switch (status) {
    case FUEL_SALES_STATUS.COMPLETED:
      textColor = 'text-blue-300';
      break;
    case FUEL_SALES_STATUS.PENDING_CLOSE:
      textColor = 'text-orange-400';
      break;

    case FUEL_SALES_STATUS.PENDING_PAYMENT:
      textColor = 'text-[#FFBF00]';
      break;

    case FUEL_SALES_STATUS.NEW:
      textColor = 'text-red-500';
      break;

    case FUEL_SALES_STATUS.FAILED:
      textColor = 'text-red-500';
      break;

    case FUEL_SALES_STATUS.PAID:
      textColor = 'text-green-500';
      break;

    case FUEL_SALES_STATUS.CLOSED:
      textColor = 'text-blue-500';
      break;

    default:
      textColor = 'text-gray-800';
  }

  return textColor;
};

export const getMediaType = (url: string) => {
  const extension = url.split('.').pop();
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];
  const videoExtensions = ['mp4', 'webm', 'ogg'];

  if (extension && imageExtensions.includes(extension)) {
    return 'image';
  } else if (extension && videoExtensions.includes(extension)) {
    return 'video';
  } else {
    return 'unknown';
  }
};

export const onRemoveFilter = (
  filter: ITableFilterItem,
  index: number,
  tableFilters: ITableFilterItem[],
  setTableFilters: (values: any) => void
) => {
  const _tableFilters: ITableFilterItem[] = [...tableFilters];

  let thisFilter = { ...tableFilters[index] };
  thisFilter = { ..._tableFilters[index], value: '' };

  _tableFilters[index] = thisFilter;

  setTableFilters([..._tableFilters]);
};

export const getNumberOfWordsFromString = (text: string, length: number) => {
  const words = text.split(' ');
  const truncatedWords = words.slice(0, length).join(' ');
  return words.length > 5 ? `${truncatedWords}...` : text;
};
