/* eslint-disable @typescript-eslint/no-explicit-any */

import { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import Button from '../../../components/form-control/Button';
import { PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';
import Input from '../../../components/form-control/Input';
import SelectDropdown from '../../../components/form-control/SelectDropdown';
import TextArea from '../../../components/form-control/TextArea';
import DatePickerInput from '../../../components/form-control/DatePickerInput';
import InputSelectMenu from '../../../components/form-control/InputSelectMenu';
import { CheckBoxInput } from '../../../components/form-control/CheckBoxInput';
import Loader from '../../../components/Loader';
import { adminCreateTask, getAllAdminAccounts, updateTask } from '../../../api/requests';
import useUserAccount from '../../../hooks/userAccountHook';
import { getFormattedAccessRight } from '../../../utils/miscFunctions';
import { CreateTaskProps, ICreateTaskFormProps } from '../../../types';
import { useQuery } from '@tanstack/react-query';
import { IRequestErrorResponse, IRequestResponse } from '../../../resources/interfaces';

export const LEAD_TASK_TYPES = [
  'Intro call',
  'Attempt to convert 1',
  'Attempt to convert 2',
  'Share First Digital Content',
  'Share Second Digital Content',
  'Attempt to convert 3',
  'Attempt to convert 4',
  'Final Attempt to convert 5'
];

export const POLICY_TASK_TYPES = [
  'Document upload / request',
  'Payment resolution / request',
  'Referee setup/ verification',
  'Account info verification',
  'Vehicle info request',
  'Renewal setup'
];

export const CUSTOMER_TASK_TYPES = [
  // 'Highlights of policy terms and conditions',
  // 'Welcome Call / Message / Video',
  // 'Sign up to Fuel rebates',
  // 'Send welcome gift',
  // 'How to log in to account and check payments',
  // 'Invite to follow socials',
  // 'Invite to review',
  // 'Send video on renewal (Month 9)',
  // 'Users should now be asked to provide their WhatsApp number',
  // 'Users should provide their birthday'
  'Welcome call',
  'In-person review',
  'Highlights of policy terms',
  'How to login to account and check payments',
  'Provide whatsapp number',
  'Invite to review ',
  'Message to follow channels',
  'Message to follow social media pages',
  'Call on add-on services',
  'Payment completion message with Gift',
  'Call and message on due -later',
  'Call and message on renewal',
  'Thank you call for renewal'
];

const CreateTask = ({
  onProceed,
  onClose,
  id,
  task,
  isEdit,
  type,
  selectedOption
}: CreateTaskProps) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [admins, setAdmins] = useState<any[]>([]);
  const { user } = useUserAccount();

  const getAllAdmins = async () => {
    try {
      const response: any = await getAllAdminAccounts();
      const _admins = response.map((admin: any) => ({
        name: `${admin.firstName} ${admin.lastName} (${getFormattedAccessRight(
          admin.accessRight
        )})`,
        value: admin.id
      }));
      setAdmins(_admins);
    } catch (error) {
      toast.error('Error fetching admins');
    }
  };

  const { isLoading } = useQuery({
    queryKey: ['getAllAdmins'],
    queryFn: getAllAdmins
  });

  const getTaskType = () => {
    switch (type) {
      case 'CUSTOMER':
        return CUSTOMER_TASK_TYPES;
      case 'LEAD':
        return LEAD_TASK_TYPES;
      case 'POLICY':
        return POLICY_TASK_TYPES;

      default:
        break;
    }
  };

  const submitHandler = (values: any) => {
    setSubmitting(true);
    const payload: ICreateTaskFormProps = {
      ...values,
      name: values.name,
      type: values.type,
      dueOn: values.dueOn,
      assignedTo: values.assignedTo,
      description: values.description,
      completed: values.completed
    };

    if (isEdit && id && task?.id) {
      updateTask(task.id, payload)
        .then((response: IRequestResponse) => {
          onProceed();
          toast.success(response?.message ?? 'Task updated successfully', { autoClose: 5000 });
        })
        .catch((error: IRequestErrorResponse) => {
          toast.error(error?.description ?? 'Error updating task');
        })
        .finally(() => setSubmitting(false));
    } else {
      if (id) {
        adminCreateTask(type, id, payload)
          .then((response: IRequestResponse) => {
            toast.success(response?.message ?? 'Task created successfully', { autoClose: 5000 });
            onProceed();
          })
          .catch((error: IRequestErrorResponse) => {
            toast.error(error?.description ?? 'Error creating task');
          })
          .finally(() => setSubmitting(false));
      }
    }
  };

  return (
    <div className="w-full">
      <div className="bg-primary-main flex justify-between">
        <div className="p-5 flex justify-between w-full items-center">
          <div className="flex items-center justify-center gap-5">
            <div className="flex items-center justify-center bg-white h-8 w-8 rounded-full gap-3">
              <PlusIcon className="h-4 w-4" />
            </div>
            <h2 className="font-bold text-lg">{isEdit ? 'Edit' : 'Create'} Task</h2>
          </div>
          <XMarkIcon onClick={onClose} width={'18px'} height={'18px'} className="cursor-pointer" />
        </div>
      </div>

      {!isLoading && admins.length > 0 && (
        <div className="p-5">
          <Formik
            initialValues={{
              name: task?.name ?? selectedOption,
              type: task?.type ?? selectedOption,
              description: task?.description ?? '',
              dueOn: task?.dueOn ? new Date(task.dueOn) : null,
              assignedTo: task?.assignedTo?.id ?? user.id ?? '',
              completed: task?.completed ?? false
            }}
            validationSchema={Yup.object({
              name: Yup.string().trim().required('Task name is required.'),
              type: Yup.string().trim().required('Task type is required.'),
              description: Yup.string().trim().required('Task description is required.'),
              dueOn: Yup.date().required('Due date is required'),
              assignedTo: Yup.string().trim().required('Select admin to assign task')
            })}
            onSubmit={(values) => {
              submitHandler(values);
            }}>
            {({
              values,
              errors,
              handleSubmit,
              handleChange,
              setFieldValue,
              handleBlur,
              touched
            }) => (
              <form className="space-y-4" onSubmit={handleSubmit}>
                <div className="grid grid-cols-1 gap-5">
                  <Input
                    name="name"
                    label="Task name"
                    value={values.name}
                    type="text"
                    required
                    error={touched.name ? errors.name : undefined}
                    helperText={touched.name ? errors.name : undefined}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-5">
                    {!isEdit && (
                      <InputSelectMenu
                        label="Task Type"
                        name="type"
                        listSelectedValue={values.type}
                        list={getTaskType()}
                        editable
                        onChange={(val: string | null) => setFieldValue('type', val)}
                        onBlur={handleBlur}
                        placeholder="Select or type a checklist"
                        error={touched.type ? errors.type : undefined}
                        helperText={touched.type ? errors.type : undefined}
                        required
                        wrapperClasses="w-full"
                      />
                    )}

                    <SelectDropdown
                      label="Assign to"
                      name="assignedTo"
                      listSelectedValue={values.assignedTo}
                      list={admins}
                      search
                      searchPlaceHolder="search by name"
                      onChange={(val: string | null) => setFieldValue('assignedTo', val)}
                      onBlur={handleBlur}
                      placeholder="Select an admin"
                      error={touched.assignedTo ? errors.assignedTo : undefined}
                      helperText={touched.assignedTo ? errors.assignedTo : undefined}
                      required
                      wrapperClasses="w-full"
                    />
                  </div>

                  <TextArea
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Description"
                    placeholder=""
                    required
                    error={touched.description ? errors.description : undefined}
                    helperText={touched.description ? errors.description : undefined}
                  />
                </div>

                <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-5  items-end">
                  <DatePickerInput
                    label="Due date"
                    id="dueOn"
                    selected={values.dueOn}
                    onChange={(val: Date | null) => {
                      setFieldValue('dueOn', val);
                    }}
                    allowEarlierSelections={false}
                    showMonthDropdown
                    showYearDropdown
                    showTimeSelect
                    placeholder="Due date"
                    error={touched.dueOn ? errors.dueOn : undefined}
                    helperText={touched.dueOn ? errors.dueOn : undefined}
                    required
                    dateFormat="dd/MM/yyyy hh:mm a"
                  />

                  <CheckBoxInput
                    label="Task Completed"
                    name="completed"
                    checked={values.completed}
                    onChange={(data: boolean) => setFieldValue('completed', data)}
                  />
                </div>

                <div className="flex flex-col-reverse md:flex-row items-center gap-5 py-5">
                  {!isEdit && (
                    <Button type="reset" label="Reset" bgColor="primary" fullWidth curved />
                  )}
                  <Button
                    type="submit"
                    label={isEdit ? 'Update' : 'Submit'}
                    fullWidth
                    bgColor="primary"
                    curved
                    className="hover:bg-primary-main"
                    loading={submitting}
                    disabled={submitting}
                  />
                </div>
              </form>
            )}
          </Formik>
        </div>
      )}

      {isLoading && (
        <div className="h-[500px] flex items-center justify-center">
          <Loader message="Just a sec..." brollyLoader />
        </div>
      )}
    </div>
  );
};

export default CreateTask;
