export enum INSURER_ACCESS_RIGHTS {
  INSURER_AGENT = 'INSURER_AGENT',
  INSURER_ADMIN = 'INSURER_ADMIN',
  INSURER_POLICY_ADMIN = 'INSURER_POLICY_ADMIN',
  INSURER_CLAIM_ADMIN = 'INSURER_CLAIM_ADMIN',
  INSURER_FINANCE_ADMIN = 'INSURER_FINANCE_ADMIN',
  INSURER_TECH_ADMIN = 'INSURER_TECH_ADMIN'
}
export enum FLEET_RIGHTS {
  FLEET_MANAGER = 'FLEET_MANAGER',
  FLEET_ADMIN = 'FLEET_ADMIN'
}
export enum AGENCY_ACCESS_RIGHTS {
  AGENCY_ADMIN = 'AGENCY_ADMIN',
  AGENT = 'AGENT'
}
export enum INTERNAL_ACCESS_RIGHTS {
  SUPER_ADMINISTRATOR = 'SUPER',
  REVIEWER = 'REVIEWER',
  ADMINISTRATOR = 'ADMINISTRATOR'
}
export enum RIDE_SHARING_ACCESS_RIGHTS {
  RIDE_SHARING_ADMIN = 'RIDE_SHARING_ADMIN',
  RIDE_SHARING_AGENT = 'RIDE_SHARING_AGENT'
}
export enum FUEL_PROVIDER_ACCESS_RIGHTS {
  FUEL_ADMIN = 'FUEL_ADMIN',
  FUEL_MANAGER = 'FUEL_MANAGER',
  FUEL_VENDOR = 'FUEL_VENDOR'
}
export enum ACCESS_RIGHTS {
  SUPER_ADMINISTRATOR = 'SUPER',
  ADMINISTRATOR = 'ADMINISTRATOR',
  TECHNICAL = 'TECHNICAL',
  AGENCY_ADMIN = 'AGENCY_ADMIN',
  AGENT = 'AGENT',
  REVIEWER = 'REVIEWER',
  INSURER_AGENT = 'INSURER_AGENT',
  INSURER_ADMIN = 'INSURER_ADMIN',
  INSURER_POLICY_ADMIN = 'INSURER_POLICY_ADMIN',
  INSURER_CLAIM_ADMIN = 'INSURER_CLAIM_ADMIN',
  INSURER_FINANCE_ADMIN = 'INSURER_FINANCE_ADMIN',
  INSURER_TECH_ADMIN = 'INSURER_TECH_ADMIN',
  INSURER_MARKETING_ADMIN = 'INSURER_MARKETING_ADMIN',
  INSURER_UNDERWRITER = 'INSURER_UNDERWRITER',
  RIDE_SHARING_ADMIN = 'RIDE_SHARING_ADMIN',
  RIDE_SHARING_AGENT = 'RIDE_SHARING_AGENT',
  FLEET_MANAGER = 'FLEET_MANAGER',
  FLEET_ADMIN = 'FLEET_ADMIN',
  FUEL_MANAGER = 'FUEL_MANAGER',
  FUEL_ADMIN = 'FUEL_ADMIN',
  FUEL_VENDOR = 'FUEL_VENDOR'
}

export enum GENDER {
  MALE = 'MALE',
  FEMALE = 'FEMALE'
  // OTHER = 'OTHER',
  // UNSPECIFIED = 'UNSPECIFIED'
}

export enum POLICY_DOCUMENT_TYPES {
  ID_CARD = 'ID_CARD',
  OWNER_PHOTO = ' OWNER_PHOTO',
  DRIVER_LICENSE_BACK = 'DRIVER_LICENSE_BACK',
  DRIVER_LICENSE_FRONT = 'DRIVER_LICENSE_FRONT',
  STAFF_ID = 'STAFF_ID',
  PAYSLIP = 'PAYSLIP',
  OTHER = 'OTHER',
  INSURANCE_STICKER = 'INSURANCE_STICKER',
  INSURANCE_CERTIFICATE = 'INSURANCE_CERTIFICATE',
  CONTRACT_SUMMARY = 'CONTRACT_SUMMARY',
  PAYMENT_AGREEMENT = 'PAYMENT_AGREEMENT',
  LOAN_AGREEMENT = 'LOAN_AGREEMENT',
  PAYMENT_LOAN_AGREEMENT = 'PAYMENT_LOAN_AGREEMENT',
  PASSPORT_PHOTO = 'PASSPORT_PHOTO',
  POLICY_STICKER = 'POLICY_STICKER',
  POLICY_SCHEDULE = 'POLICY_SCHEDULE',
  ROADWORTHY_CERTIFICATE = 'ROADWORTHY_CERTIFICATE',
  ROADWORTHY = 'ROADWORTHY',
  RENEWAL_NOTICE = 'RENEWAL_NOTICE',
  VEHICLE_PHOTO_FRONT = 'VEHICLE_PHOTO_FRONT',
  VEHICLE_PHOTO_BACK = 'VEHICLE_PHOTO_BACK',
  VEHICLE_PHOTO_LEFT = 'VEHICLE_PHOTO_LEFT',
  VEHICLE_PHOTO_RIGHT = 'VEHICLE_PHOTO_RIGHT',
  POLICY = 'POLICY'
}

export enum INSTALLMENT_TYPE {
  FULL_PAYMENT = 'FULL_PAYMENT',
  WEEKLY_PAYMENT = 'WEEKLY_PAYMENT'
}

export enum DISCOUNT_TYPES {
  AMOUNT = 'AMOUNT',
  PERCENTAGE = 'PERCENTAGE'
}

export enum INSTALLMENT_TYPES {
  FULL_PAYMENT = 'FULL_PAYMENT',
  TWELVE_MONTHS = 'TWELVE_MONTHS',
  NINE_MONTHS = 'NINE_MONTHS',
  SIX_MONTHS = 'SIX_MONTHS',
  THREE_MONTHS = 'THREE_MONTHS',
  TWELVE_WEEKS = 'TWELVE_WEEKS',
  TEN_WEEKS = 'TEN_WEEKS',
  NINE_WEEKS = 'NINE_WEEKS',
  ONE_HUNDRED_AND_TWENTY_DAYS = 'ONE_HUNDRED_AND_TWENTY_DAYS',
  NINETY_DAYS = 'NINETY_DAYS',
  SEVENTY_DAYS = 'SEVENTY_DAYS',
  SIXTY_FIVE_DAYS = 'SIXTY_FIVE_DAYS',
  FIFTY_FIVE_DAYS = 'FIFTY_FIVE_DAYS',
  SIXTY_DAYS = 'SIXTY_DAYS',
  FORTY_DAYS = 'FORTY_DAYS',
  THREE_DAYS_TEST = 'THREE_DAYS_TEST',
  MONTHLY = 'MONTHLY',
  WEEKLY = 'WEEKLY',
  DAILY = 'DAILY'
}

export enum COVER_PERIODS {
  ONE_YEAR = 12,
  NINE_MONTHS = 9,
  SIX_MONTHS = 6,
  THREE_MONTHS = 3
}

export enum POLICY_STATUS_OPTIONS {
  ACTIVE = 'active',
  INACTIVE = 'inactive'
}

export enum DISCOUNT_STATUS_OPTIONS {
  TRUE = 'Active',
  FALSE = 'Inactive'
}

export enum DISCOUNT_RELATION_OPTIONS {
  ONE_TIME = 'ONE TIME',
  INITIAL_DEPOSIT = 'INITIAL DEPOSIT',
  MONTHLY_INSTALLMENTS = 'MONTHLY INSTALLMENTS'
}

export enum INSURER_STATUS_OPTIONS {
  TRUE = 'ACTIVE',
  FALSE = 'INACTIVE'
}

export enum ADMIN_POLICY_STATUS_ACTIONS {
  DUE_LATER = 'DUE_LATER',
  APPROVE = 'APPROVE',
  HOLD = 'HOLD',
  REJECT = 'REJECT'
}

export enum POLICY_REVIEW_STATES {
  NEW = 'NEW',
  ON_HOLD = 'ON_HOLD',
  DUE_LATER = 'DUE_LATER',
  IN_APPROVAL = 'IN_APPROVAL',
  APPROVED = 'APPROVED',
  CANCELLED = 'CANCELLED',
  DECLINED = 'DECLINED',
  IN_REVIEW = 'IN_REVIEW',
  ARCHIVED = 'ARCHIVED',
  EXPIRED = 'EXPIRED'
}

export enum INSURANCE_TYPE {
  COMPREHENSIVE = 'Comprehensive',
  THIRD_PARTY = 'Third Party'
}

export enum LEAD_STATUS {
  COLD = 'COLD',
  WARM = 'WARM',
  CLOSED = 'CLOSED',
  PAID = 'PAID'
}

export enum LEAD_TAGS { 
  NEW = 'NEW',
  CONTACTED = 'CONTACTED',
  QUALIFIED = 'QUALIFIED',
  UNRESPONSIVE = 'UNRESPONSIVE',
  DISQUALIFIED = 'DISQUALIFIED',
  IN_NEGOTIATION = 'IN_NEGOTIATION',
  NURTURING = 'NURTURING',
  CONVERTED = 'CONVERTED'
}

export enum LEAD_TAGS_COLD { 
  NEW = 'NEW',
  CONTACTED = 'CONTACTED',
  QUALIFIED = 'QUALIFIED',
  UNRESPONSIVE = 'UNRESPONSIVE',
  DISQUALIFIED = 'DISQUALIFIED'
}

export enum LEAD_TAGS_WARM { 
  IN_NEGOTIATION = 'IN_NEGOTIATION',
  NURTURING = 'NURTURING'
}

export enum LEAD_TAGS_CLOSED { 
  CONVERTED = 'CONVERTED'
}

export const VEHICLE_BODY_TYPES_LIST = [
  'SUV',
  'CONVERTIBLE',
  'HATCHBACK',
  'STATION WAGON',
  'COUPE',
  'VAN',
  'PICKUP',
  'SALOON/SEDAN',
  'MINI/SMALL BUS',
  'MAXI/BIG BUS',
  'SPORT CAR',
  'ARTICULATED TRUCK',
  'MOTORCYCLE'
];

export enum VEHICLE_BODY_TYPES {
  SUV = 'SUV',
  CONVERTIBLE = 'CONVERTIBLE',
  HATCHBACK = 'HATCHBACK',
  STATION_WAGON = 'STATION_WAGON',
  COUPE = 'COUPE',
  VAN = 'VAN',
  PICKUP = 'PICKUP',
  SALOON_SEDAN = 'SALOON_SEDAN',
  MINI_OR_SMALL_BUS = 'MINI_SMALL_BUS',
  MAXI_OR_BIG_BUS = 'MAXI_BIG_BUS',
  SPORT_CAR = 'SPORTS_CAR',
  ARTICULATED_TRUCK = 'ARTICULATED_TRUCK',
  MOTOR_BIKE = 'MOTORCYCLE'
}

export enum ID_TYPES_DTO {
  PASSPORT = 'PASSPORT',
  NATIONAL_ID = 'NATIONAL_ID',
  VOTER_ID = 'VOTER_ID',
  DRIVER_LICENSE = 'DRIVER_LICENSE',
  SOCIAL_SECURITY = 'SOCIAL_SECURITY'
}

export enum VEHICLES_MAKE {
  HYUNDAI = 'Hyundai',
  TOYOTA = 'Toyota',
  MERCEDEZ = 'Mercedez',
  BMW = 'BMW',
  AUDI = 'Audi',
  HONDA = 'Honda',
  CHEVROLET = 'Chevrolet',
  CADILLAC = 'Cadillac',
  FERRARI = 'Ferrari',
  FORD = 'Ford',
  TATA = 'Tata'
}

export enum LEAD_SOURCE {
  ASEMPA_FM = 'ASEMPA_FM',
  BOLT_APP = 'BOLT_APP',
  CITI_FM = 'CITI_FM',
  CITIFM_ONLINE = 'CITIFM_ONLINE',
  CUSTOMER_REFERRAL = 'CUSTOMER_REFERRAL',
  DIRECT_SALES = 'DIRECT_SALES',
  FACEBOOK = 'FACEBOOK',
  GHANA_WEB = 'GHANAWEB',
  GOOGLE_BUSINESS = 'GOOGLE_BUSINESS',
  INSTAGRAM = 'INSTAGRAM',
  JOY_FM = 'JOY_FM',
  LINKEDIN = 'LINKEDIN',
  MYJOY_ONLINE = 'MYJOY_ONLINE',
  OTHER = 'OTHER',
  SOCIAL_MEDIA = 'SOCIAL_MEDIA',
  TEAM_DIRECT = 'TEAM_DIRECT',
  TIKTOK = 'TIKTOK',
  WEBSITES_OR_BLOGS = 'WEBSITES_OR_BLOGS',
  WHATSAPP = 'WHATSAPP',
  YANGO_APP = 'YANGO_APP'
}

export enum ADMIN_USER_ROLES {
  SUPER = 'SUPER',
  ADMINISTRATOR = 'ADMINISTRATOR',
  AGENT = 'AGENT'
}

export enum ADMIN_PAYMENTS_STATUS_FILTER_OPTIONS {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  OVERDUE = 'OVERDUE'
}
export enum CLAIM_FILTER_OPTIONS {
  ACKNOWLEDGEMENT = 'ACKNOWLEDGEMENT',
  ASSESSMENT = 'ASSESSMENT',
  NEW = 'NEW',
  SETTLEMENT = 'SETTLEMENT',
  PAYMENT = 'PAYMENT'
}
export enum TEAM_TYPES {
  INTERNAL = 'INTERNAL',
  AGENCY = 'AGENCY',
  REPAIR_CENTER = 'REPAIR_CENTER',
  INSURER = 'INSURER',
  RIDE_SHARING = 'RIDE_SHARING',
  AFFILIATE = 'AFFILIATE',
  FLEET = 'FLEET',
  FUEL_PROVIDER = 'FUEL_PROVIDER',
  OTHER = 'OTHER'
}
export enum PARTNER_TYPES {
  REPAIR_CENTER = 'REPAIR_CENTER',
  INSURER = 'INSURER',
  RIDE_SHARING = 'RIDE_SHARING',
  AFFILIATE = 'AFFILIATE',
  FLEET = 'FLEET',
  FUEL_PROVIDER = 'FUEL_PROVIDER',
  OTHER = 'OTHER'
}

export enum PAYMENT_STATUS_STATES {
  CREATED = 'CREATED',
  SUCCESS = 'SUCCESS',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
  OVERDUE = 'OVERDUE',
  LONG_OVERDUE = 'LONG_OVERDUE',
  APPROVAL = 'APPROVAL'
}

export enum POLICY_STATUSES {
  ACTIVE = 'active',
  PENDING = 'pending',
  IN_REVIEW = 'in_review',
  ENDED = 'expired'
}

export enum VEHICLE_LIGHTING_TYPES {
  LOW_BEAM_DIPPED_HEADLIGHTS = 'LOW_BEAM_DIPPED_HEADLIGHTS',
  FULL_BEAM_HEADLIGHTS = 'FULL_BEAM_HEADLIGHTS',
  FOG_LIGHTS = 'FOG_LIGHTS',
  RENTAL_OR_HIRE = 'HAZARD_LIGHTS'
}

export const CAR_INSURERS_LIST = [
  'Activa International Insurance',
  'Allianz Insurance',
  'Beige Assure',
  'Best Assurance',
  'Bedrock Insurance',
  'Coronation Insurance',
  'Donewell Insurance',
  'Enterprise Life Assurance',
  'Esich Life Assurance',
  'Ghana Union Insurance',
  'Glico General Insurance',
  'Heritage Energy Insurance',
  'Hollard Insurance',
  'Imperial General Assurance',
  'Loyalty Insurance',
  'MiLife Company',
  'Millennium Insurance',
  'NSIA Insurance',
  'Priority Insurance',
  'Prudential Life Insurance',
  'Quality Insurance',
  'REGNEM Insurance',
  'Regency Nem Insurance',
  'SUNU Insurance',
  'SIC Insurance',
  'Saham Life Insurance',
  'Serene Insurance',
  'Star Assurance',
  'Starlife Insurance',
  'Vanguard Insurance',
  'Provident Insurance',
  'Prime Insurance'
];

export enum ToastType {
  SUCCESS = 'success',
  INFO = 'info',
  ERROR = 'error',
  WARNING = 'warning',
  DEFAULT = 'default'
}

export enum UPLOADABLE_DOCUMENT_TYPES {
  DRIVER_LICENSE_FRONT = 'DRIVER_LICENSE_FRONT',
  DRIVER_LICENSE_BACK = 'DRIVER_LICENSE_BACK',
  ID_CARD = 'ID_CARD',
  STAFF_ID = 'STAFF_ID',
  PAYSLIP = 'PAYSLIP',
  OTHER = 'OTHER',
  INSURANCE_STICKER = 'INSURANCE_STICKER',
  INSURANCE_CERTIFICATE = 'INSURANCE_CERTIFICATE',
  CONTRACT_SUMMARY = 'CONTRACT_SUMMARY',
  PAYMENT_AGREEMENT = 'PAYMENT_AGREEMENT',
  PASSPORT_PHOTO = 'PASSPORT_PHOTO',
  POLICY_STICKER = 'POLICY_STICKER',
  ROADWORTHY_CERTIFICATE = 'ROADWORTHY_CERTIFICATE',
  ROADWORTHY = 'ROADWORTHY',
  RENEWAL_NOTICE = ' RENEWAL_NOTICE',
  VEHICLE_PHOTO_FRONT = 'VEHICLE_PHOTO_FRONT',
  VEHICLE_PHOTO_BACK = 'VEHICLE_PHOTO_BACK',
  VEHICLE_PHOTO_LEFT = 'VEHICLE_PHOTO_LEFT',
  VEHICLE_PHOTO_RIGHT = 'VEHICLE_PHOTO_RIGHT',
  DAMAGED_VEHICLE_FRONT = 'FRONT',
  DAMAGED_VEHICLE_BACK = 'BACK',
  DAMAGED_VEHICLE_LEFT = 'LEFT',
  DAMAGED_VEHICLE_RIGHT = 'RIGHT'
}

export enum UPLOAD_IMAGE_ENDPOINTS {
  CUSTOMER = '/admin/document/customer/upload',
  VEHICLE = '/admin/document/vehicle/upload',
  POLICY = '/admin/document/policy/upload',
  STAFF = '/admin/staff/account/photo',
  INSURER_FILE = '/admin/insurers/upload'
}

export enum DELETE_IMAGE_ENDPOINTS {
  INSURER_LOGO = '/admin/insurers/insurer/logo',
  INSURER_BANNER = '/admin/insurers/insurer/banner'
}

export enum PAYMENTS_FILTER_OPTIONS {
  OUTSTANDING = 'OUTSTANDING',
  OVERDUE = 'OVERDUE',
  LONG_OVERDUE = 'LONG_OVERDUE',
  PENDING = 'PENDING',
  DUE_LATER = 'DUE_LATER',
  DUE_TODAY = 'DUE_TODAY',
  DUE_THIS_WEEK = 'DUE_THIS_WEEK',
  DUE_THIS_MONTH = 'DUE_THIS_MONTH',
  SUCCESS = 'SUCCESS'
}

export enum AGENCY_PAYMENT_ACCOUNT_TYPES {
  BANK = 'BANK',
  MOBILE_MONEY = 'MOBILE_MONEY'
}

export enum PAYOUT_REVIEW_STATES {
  REQUEST = 'REQUEST',
  APPROVE = 'APPROVE',
  PAID = 'PAID'
}

export enum MOBILE_MONEY_NETWORKS {
  MTN = 'MTN',
  VODAFONE = 'VODAFONE'
  // AIRTEL = "AIRTEL"
}

export enum CURRENCIES {
  USD = 'USD',
  GHS = 'GHS',
  EUR = 'EUR'
}

export enum COMMUNITY_TYPES {
  UNION = 'UNION',
  ASSOCIATION = 'ASSOCIATION',
  SCHOOL = 'SCHOOL',
  CHURCH = 'CHURCH',
  COMPANY = 'COMPANY',
  OTHER = 'OTHER'
}

export enum COMMUNITY_CHANNEL_OPTIONS {
  EMAIL = 'EMAIL',
  PUSH = 'PUSH',
  SMS = 'SMS'
}

export enum POLICY_TYPES {
  AUTO = 'auto',
  HOME = 'home',
  BUSINESS = 'business',
  TRAVEL = 'travel'
}

export enum POLICY_TYPES_PATH {
  AUTO = '/policies/?type=auto',
  HOME = '/policies/?type=home',
  BUSINESS = '/policies/?type=business',
  TRAVEL = '/policies/?type=travel'
}

export enum CLAIM_STATUSES {
  ACTIVE = 'active',
  PENDING = 'pending',
  IN_REVIEW = 'in_review',
  ENDED = 'expired',
  INACTIVE = 'inactive'
}

export enum DOWNLOAD_FILE_TYPES {
  CSV = 'CSV',
  PDF = 'PDF'
  // PHOTO = "PHOTO",
  // VIDEO = "VIDEO",
}

export enum DOWNLOAD_RESOURCE_TYPES {
  CLAIM = 'CLAIM',
  PAYOUT = 'PAYOUT',
  POLICY = 'POLICY',
  SALE = 'SALE'
}

export enum REDACT_INFO_TYPES {
  EMAIL = 'EMAIL',
  PHONE = 'PHONE'
}

export enum INSURER_FILE_TYPES {
  BANNER = 'BANNER',
  BANNER_MOBILE = 'BANNER_MOBILE',
  CSV = 'CSV',
  LOGO = 'LOGO',
  PDF = 'PDF',
  PHOTO = 'PHOTO',
  VIDEO = 'VIDEO'
}
export enum PAYMENT_PROCESSORS {
  HUBTEL = 'HUBTEL',
  PAYSTACK = 'PAYSTACK',
  FLUTTERWAVE = 'FLUTTERWAVE',
  IT_CONSORTIUM = 'IT_CONSORTIUM',
  BANK = 'BANK'
}
export enum PAYMENT_CHANNELS {
  BANK_TRANSFER = 'BANK_TRANSFER',
  MOBILE_MONEY = 'MOBILE_MONEY',
  CARD = 'CARD',
  CASH = 'CASH',
  CHEQUE_DEPOSIT = 'CHEQUE_DEPOSIT',
  VOUCHER = 'VOUCHER'
}

export enum ENVIRONMENTS {
  DEVELOPMENT = 'development',
  PRODUCTION = 'production',
  TEST = 'test',
  DEV = 'dev'
}

export enum PAYMENT_RESOURCE_TYPE {
  HOME_POLICY = 'home-policy',
  AUTO_POLICY = 'auto-policy',
  HAILING_SERVICES = 'hailing-services'
}

export enum HUBTEL_RESOURCE_PAYMENT_LINKS {
  HOME_POLICY = `/home/policy/pay`,
  AUTO_POLICY = `/user/policy/pay`,
  HAILING_SERVICES = `/services/vehicle/pay/installments`
}

export enum PAYMENT_OPTIONS {
  CARD = 'CARD',
  MOBILE_MONEY = 'MOBILE_MONEY',
  BANK = 'BANK'
}
export enum DOWNLOAD_ACTION_TYPES {
  SUPER_DOWNLOAD_INSURER_POLICIES = 'SUPER_DOWNLOAD_INSURER_POLICIES',
  INSURER_DOWNLOAD_POLICIES = 'INSURER_DOWNLOAD_POLICIES',
  DOWNLOAD_PAYOUT_POLICIES = 'DOWNLOAD_PAYOUT_POLICIES',
  INSURER_DOWNLOAD_PAYOUTS = 'INSURER_DOWNLOAD_PAYOUTS',
  INSURER_DOWNLOAD_SALES = 'INSURER_DOWNLOAD_SALES'
}

export enum ACTIVITY_STREAMS_TYPE {
  TASK = 'Task',
  PAYMENT = 'Payment',
  VEHICLE = 'Vehicle',
  POLICY = 'Policy',
  CREATE = 'Create',
  UPDATE = 'Update',
  LEAD = 'Lead'
}

export enum NOTIFICATION_TYPE {
  POLICY = 'POLICY',
  TASK = 'TASK',
  LEAD = 'LEAD',
  PAYMENT = 'PAYMENT',
  QUOTE = 'QUOTE',
  COMMISSION = 'COMMISSION',
  PAYOUT = 'PAYOUT',
  CUSTOMER = 'CUSTOMER',
  CLAIM = 'CLAIM',
  COMMUNITY = 'COMMUNITY',
  TEAM = 'TEAM',
  STAFF = 'STAFF'
}

export enum APPROVAL_TYPE {
  POLICY = 'POLICY',
  LEAD = 'LEAD',
  PAYMENT = 'PAYMENT',
  QUOTE = 'QUOTE',
  COMMISSION = 'COMMISSION',
  PAYOUT = 'PAYOUT',
  CUSTOMER = 'CUSTOMER',
  CLAIM = 'CLAIM',
  COMMUNITY = 'COMMUNITY',
  TEAM = 'TEAM'
}

export enum TASK_EVENT {
  POLICY = 'POLICY',
  LEAD = 'LEAD',
  PAYMENT = 'PAYMENT',
  QUOTE = 'QUOTE',
  COMMISSION = 'COMMISSION',
  PAYOUT = 'PAYOUT',
  CUSTOMER = 'CUSTOMER',
  CLAIM = 'CLAIM',
  COMMUNITY = 'COMMUNITY',
  TEAM = 'TEAM'
}

export enum CLAIMS_VEHICLE_USAGE_TYPES {
  PRIVATE_TRIP = 'PRIVATE_TRIP',
  BUSINESS_TRIP = 'BUSINESS_TRIP',
  TRANSPORTING_BUSINESS = 'TRANSPORTING_PASSENGERS',
  TRANSPORTING_GOODS_OR_MECHANDISE = 'TRANSPORTING_GOODS_OR_MECHANDISE',
  RENTAL_OR_HIRE = 'RENTAL_OR_HIRE',
  DISPATCHING_OR_DELIVERY = 'DISPATCHING_OR_DELIVERY',
  OTHERS = 'OTHERS_(PLEASE_GIVE_DETAILS)'
}

export enum INCIDENT_REPORTED_TIME_OPTIONS {
  SAME_DAY = 'SAME_DAY',
  NEXT_DAY = 'NEXT_DAY',
  TWO_DAYS_LATER = 'TWO_DAYS_LATER',
  MORE_THAN_TWO_DAYS = 'MORE_THAN_TWO_DAYS'
}

export enum DRIVER_LICENSE_VEHICLE_GROUPS {
  MOTORCYCLES = 'Motorcycles',
  SALOON_PICKUPS_TRUCKS_SUVS = 'Saloon cars, pick-up trucks, and SUVs',
  BUSES_MEDIUM_GOODS_UP_TO_1000KG = 'Buses and medium goods - carrying vehicles(up to 1000kg)',
  BUSES_COACHES_HEAVY_GOODS_35000KG = 'Buses, coaches, heavy goods vehicles(up to 35000kg)',
  AGRIC_AND_EARTH_MOVING_EQUIPMENT = 'Agric and earth - moving equipment',
  BUSES_COACHES_TRUCKS_ABOVE_35000KG = 'Buses, coaches, trucks(above 35, 000kg)'
}

export enum TASK_STATUSES {
  NEW = 'NEW',
  DONE = 'DONE',
  IN_PROGRESS = 'IN_PROGRESS',
  INAPPROVAL = 'INAPPROVAL'
}

export enum FUEL_SALES_STATUS {
  FAILED = 'FAILED',
  NEW = 'NEW',
  PENDING_PAYMENT = 'PENDING_PAYMENT',
  PENDING_SETTLEMENT = 'PENDING_SETTLEMENT',
  PENDING_CLOSE = 'PENDING_CLOSE',
  CLOSED = 'CLOSED',
  COMPLETED = 'COMPLETED',
  PAID = 'PAID'
}

export enum FUEL_SALES_PAYMENT_MODES {
  CASH = 'CASH',
  MTN = 'MTN',
  TELECEL = 'TELECEL',
  AT = 'AT',
  GHQR = 'GHQR',
  VOUCHER = 'VOUCHER'
}
