import { UserCircleIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { toast } from 'react-toastify';
import Button from '../../../components/form-control/Button';
import Input from '../../../components/form-control/Input';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { startCase } from 'lodash';
import moment from 'moment';
import { updateLead } from '../../../api/requests';
import SelectMenu from '../../../components/form-control/SelectMenu';
import {
  GENDER,
  LEAD_STATUS,
  LEAD_SOURCE,
  INSURANCE_TYPE,
  CAR_INSURERS_LIST,
  LEAD_TAGS_COLD,
  LEAD_TAGS_WARM,
  LEAD_TAGS_CLOSED,
  LEAD_TAGS
} from '../../../resources/enums';
import { getFormattedPhoneNumber, getPhoneNumberFromShortCodeFormat } from '../../../utils/miscFunctions';
import { Lead } from '../../../models/lead/Lead';
import { INPUT_DATE_FORMAT } from '../../../resources/constants';
import { useEffect, useState } from 'react';

const GenderList: Array<string> = Object.values(GENDER).map((value) => startCase(value));
const LeadStatus: Array<string> = Object.values(LEAD_STATUS).map((value) => startCase(value));
const LeadSource: Array<string> = Object.values(LEAD_SOURCE).map((value) => value);
//const LeadTags: Array<string> = Object.values(LEAD_TAGS).map((value) => value);
const InsuranceType: Array<string> = Object.values(INSURANCE_TYPE).map((value) => startCase(value));
const InsurersList: Array<string> = Object.values(CAR_INSURERS_LIST).map((value) =>
  startCase(value)
);

interface EditLeadProps {
  onProceed: () => void;
  onClose: () => void;
  lead: Lead;
  section: string;
}

const EditLead = ({ onProceed, onClose, lead, section }: EditLeadProps) => {
  const customerName = `${lead?.firstname} ${lead?.lastname}`;
  const [loading, setLoading] = useState<boolean>(false);

  function listTags(status: string) {
    const [leadTagsList, setLeadTagsList] = useState<string[]>([]);
  useEffect(() => {
    let tagsFromEnum: string[] = [];
    if (status === 'COLD') {
      tagsFromEnum = Object.values(LEAD_TAGS_COLD).map((value) => startCase(value));
    } else if (status === 'WARM') {
      tagsFromEnum = Object.values(LEAD_TAGS_WARM).map((value) => value);
    } else if (status === 'CLOSED') {
      tagsFromEnum = Object.values(LEAD_TAGS_CLOSED).map((value) => startCase(value));
    } else if (status === 'PAID') {
      tagsFromEnum = Object.values(LEAD_TAGS_CLOSED).map((value) => startCase(value));
    }
   
    setLeadTagsList(tagsFromEnum);
    
  }, [status,leadTagsList]);
  
  return leadTagsList;
  }
 

  return (
    <div className="">
      <div className="w-full flex justify-between bg-primary-main px-9  py-6 items-center">
        <div className="flex gap-3 items-center ">
          <UserCircleIcon className="h-10" />
          <div className="">
            <h1 className="text-lg font-extrabold">{customerName ? customerName : 'N/A'}</h1>
          </div>
        </div>
        <div className="">
          <XMarkIcon className="h-5 w-5 cursor-pointer" onClick={onClose} />
        </div>
      </div>

      <Formik
        initialValues={{
          firstname: lead.firstname ?? '',
          lastname: lead?.lastname ?? '',
          phone: lead.phone ? getPhoneNumberFromShortCodeFormat(lead?.phone) : '',
          email: lead?.email ?? '',
          gender: lead?.gender ?? '',
          description: lead?.description ?? '',
          leadSource: lead.leadSource ?? '',
          leadStatus: lead?.leadStatus ?? '',
          currentInsurer: lead?.currentInsurer ?? '',
          insuranceType: lead?.insuranceType ?? '',
          registrationNumber: lead?.registrationNumber?.toUpperCase() ?? '',
          expiryDate: lead?.expiryDate ?? null,
          tag:lead?.tag ?? ''
        }}
        enableReinitialize={true}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={Yup.object().shape({
          // firstname: Yup.string().trim().required('First name is required.'),
          // lastname: Yup.string().trim().required('Last name is required.'),
          // email: Yup.string().email('Email provided is invalid.').required('Email is requied.')
        })}
        onSubmit={(values) => {
          const payload: any = {
            ...values,
            phone: values.phone ? getFormattedPhoneNumber(values.phone) : "",
            expiryDate: values.expiryDate
              ? moment(values.expiryDate).format(INPUT_DATE_FORMAT)
              : null,
            tag: values.tag ? values.tag : 'N/A'
          };

          const id = lead.id;

          if (id) setLoading(true);
          updateLead(id, payload)
            .then((response: any) => {
              onProceed();
              toast.success(response.message ?? 'Signup updated successfully.');
            })
            .catch((error: any) => {
              toast.error(error.description ?? 'Something went wrong');
            })
            .finally(() => setLoading(false));
        }}>
        {({
          values,
          errors,
          isSubmitting,
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          touched
        }) => (
          <form className="" onSubmit={handleSubmit}>
            <div className="p-5">
              <div className=" ">
                {section == 'CONTACT_INFO' && (
                  <div className="grid  grid-cols-1 md:grid-cols-3 gap-5  ">
                    <div className="">
                      <Input
                        type={'text'}
                        name="firstname"
                        value={values.firstname}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="First Name"
                        required
                        error={touched.firstname ? errors.firstname : undefined}
                        helperText={touched?.firstname ? errors.firstname : undefined}
                      />
                    </div>
                    <div className="">
                      <Input
                        type={'text'}
                        name="lastname"
                        value={values.lastname}
                        onChange={handleChange}
                        onBlur={handleChange}
                        label="Last Name"
                        required
                        error={touched.lastname ? errors.lastname : undefined}
                        helperText={touched?.lastname ? errors.lastname : undefined}
                      />
                    </div>
                    <div className="">
                      <Input
                        type={'text'}
                        id={'email'}
                        name="email"
                        value={values.email.trim()}
                        onChange={handleChange}
                        onBlur={handleChange}
                        label="Email Address"
                        required
                        error={touched.email ? errors.email : undefined}
                        helperText={touched?.email ? errors.email : undefined}
                      />
                    </div>
                    <div className="">
                      <Input
                        type={'text'}
                        id={'phone'}
                        name="phone"
                        value={values.phone}
                        onChange={handleChange}
                        onBlur={handleChange}
                        label="Phone"
                        required
                        error={touched.phone ? errors.phone : undefined}
                        helperText={touched?.phone ? errors.phone : undefined}
                      />
                    </div>
                    <div className="">
                      <SelectMenu
                        onChange={(option: string | null) => setFieldValue('gender', option)}
                        listSelectedValue={values.gender}
                        name="gender"
                        label="Gender"
                        list={GenderList}
                        error={touched.gender ? errors.gender : undefined}
                        helperText={touched?.gender ? errors.gender : undefined}
                      />
                    </div>
                  </div>
                )}

                {section == 'INSURANCE_INFO' && (
                  <div className="grid  grid-cols-1 md:grid-cols-2 gap-5  ">
                    <div className="">
                      <Input
                        type={'text'}
                        name="registrationNumber"
                        value={values.registrationNumber}
                        onChange={handleChange}
                        onBlur={handleChange}
                        label="Registration Number "
                        error={touched.registrationNumber ? errors.registrationNumber : undefined}
                        helperText={
                          touched?.registrationNumber ? errors.registrationNumber : undefined
                        }
                      />
                    </div>
                    <div className="">
                      <SelectMenu
                        onChange={(option: string | null) => setFieldValue('leadStatus', option)}
                        listSelectedValue={values.leadStatus}
                        name="leadStatus"
                        label="Signup Stage"
                        list={LeadStatus}
                        error={touched.leadStatus ? errors.leadStatus : undefined}
                        helperText={touched?.leadStatus ? errors.leadStatus : undefined}
                      />
                    </div>
                    <div className="">
                      <SelectMenu
                        onChange={(option: string | null) => setFieldValue('tag', option)}
                        listSelectedValue={values.tag}
                        name="tag"
                        label="Tag"
                        list={listTags(values.leadStatus)}
                        error={touched.tag ? errors.tag : undefined}
                        helperText={touched?.tag ? errors.tag : undefined}
                      />
                    </div>
                    <div className="">
                      <SelectMenu
                        onChange={(option: string | null) => setFieldValue('leadSource', option)}
                        listSelectedValue={values.leadSource}
                        name="leadSource"
                        label="Signup Source"
                         
                        error={touched.leadSource ? errors.leadSource : undefined}
                        helperText={touched?.leadSource ? errors.leadSource : undefined}
                      />
                    </div>
                    <div className="">
                      <SelectMenu
                        onChange={(option: string | null) =>
                          setFieldValue('currentInsurer', option)
                        }
                        listSelectedValue={values.currentInsurer}
                        name="currentInsurer"
                        label="Current Insurer"
                        list={InsurersList}
                        error={touched.currentInsurer ? errors.currentInsurer : undefined}
                        helperText={touched?.currentInsurer ? errors.currentInsurer : undefined}
                      />
                    </div>
                    <div className="">
                      <SelectMenu
                        onChange={(option: string | null) => setFieldValue('insuranceType', option)}
                        listSelectedValue={values.insuranceType}
                        name="insuranceType"
                        label="Insurance Type"
                        list={InsuranceType}
                        error={touched.insuranceType ? errors.insuranceType : undefined}
                        helperText={touched?.insuranceType ? errors.insuranceType : undefined}
                      />
                    </div>
                    <div className=" ">
                      <Input
                        type="date"
                        name="expiryDate"
                        label="Insurance Expiry Date "
                        placeholder="Eg. 12/05/2023"
                        value={values.expiryDate}
                        onChange={handleChange}
                        onBlur={handleChange}
                        error={touched.expiryDate ? errors.expiryDate : undefined}
                        helperText={touched?.expiryDate ? errors.expiryDate : undefined}
                      />
                    </div>
                  </div>
                )}

                {section == 'INSURANCE_INFO_TAG_ONLY' && (
                  <div className="grid  grid-cols-1 md:grid-cols-2 gap-5  ">
                    <div className="">
                      <Input
                        type={'text'}
                        name="registrationNumber"
                        value={values.registrationNumber}
                        disabled
                        onChange={handleChange}
                        onBlur={handleChange}
                        label="Registration Number "
                        error={touched.registrationNumber ? errors.registrationNumber : undefined}
                        helperText={
                          touched?.registrationNumber ? errors.registrationNumber : undefined
                        }
                      />
                    </div>
                    <div className="">
                      <SelectMenu
                        onChange={(option: string | null) => setFieldValue('leadStatus', option)}
                        listSelectedValue={values.leadStatus}
                        name="leadStatus"
                        label="Signup Stage"
                        list={LeadStatus}
                        error={touched.leadStatus ? errors.leadStatus : undefined}
                        helperText={touched?.leadStatus ? errors.leadStatus : undefined}
                      />
                    </div>
                    <div className="">
                      <SelectMenu
                        onChange={(option: string | null) => setFieldValue('tag', option)}
                        listSelectedValue={values.tag}
                        name="tag"
                        label="Tag"
                        list={listTags(values.leadStatus)}
                        error={touched.tag ? errors.tag : undefined}
                        helperText={touched?.tag ? errors.tag : undefined}
                      />
                    </div>
                    <div className="">
                      <SelectMenu
                      disabled
                        onChange={(option: string | null) => setFieldValue('leadSource', option)}
                        listSelectedValue={values.leadSource}
                        name="leadSource"
                        label="Signup Source"
                         
                        error={touched.leadSource ? errors.leadSource : undefined}
                        helperText={touched?.leadSource ? errors.leadSource : undefined}
                      />
                    </div>
                    <div className="">
                      <SelectMenu
                      disabled
                        onChange={(option: string | null) =>
                          setFieldValue('currentInsurer', option)
                        }
                        listSelectedValue={values.currentInsurer}
                        name="currentInsurer"
                        label="Current Insurer"
                        list={InsurersList}
                        error={touched.currentInsurer ? errors.currentInsurer : undefined}
                        helperText={touched?.currentInsurer ? errors.currentInsurer : undefined}
                      />
                    </div>
                    <div className="">
                      <SelectMenu
                      disabled
                        onChange={(option: string | null) => setFieldValue('insuranceType', option)}
                        listSelectedValue={values.insuranceType}
                        name="insuranceType"
                        label="Insurance Type"
                        list={InsuranceType}
                        error={touched.insuranceType ? errors.insuranceType : undefined}
                        helperText={touched?.insuranceType ? errors.insuranceType : undefined}
                      />
                    </div>
                    <div className="hidden ">
                      <Input
                      
                        type="date"
                        name="expiryDate"
                        label="Insurance Expiry Date "
                        placeholder="Eg. 12/05/2023"
                        value={values.expiryDate}
                        onChange={handleChange}
                        onBlur={handleChange}
                        error={touched.expiryDate ? errors.expiryDate : undefined}
                        helperText={touched?.expiryDate ? errors.expiryDate : undefined}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="flex-col-reverse gap-5 flex md:flex-row  mt-10 ">
                <div className="w-full md:w-1/2">
                  <Button
                    label="Cancel"
                    onClick={onClose}
                    curved
                    className=" w-full px-[12px] py-[12px] h-[45px] rounded border border-primary-border hover:bg-primary-main "
                  />
                </div>

                <div className="w-full md:w-1/2">
                  <Button
                    label="Save"
                    className="w-full px-[12px] py-[12px] h-[45px] rounded border border-primary-border hover:bg-primary-main "
                    onClick={handleSubmit}
                    disabled={loading}
                    loading={loading}
                    curved
                    bgColor="primary"
                  />
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default EditLead;
