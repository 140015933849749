import { useEffect, useState } from 'react';
import Button from '../../../components/form-control/Button';
import Input from '../../../components/form-control/Input';
import { Formik } from 'formik';
import * as Yup from 'yup';
import TextArea from '../../../components/form/TextArea';
import { createLeads } from '../../../api/requests';
import {
  CAR_INSURERS_LIST,
  GENDER,
  INSURANCE_TYPE,
  LEAD_SOURCE,
  LEAD_STATUS,
  LEAD_TAGS,
  LEAD_TAGS_CLOSED,
  LEAD_TAGS_COLD,
  LEAD_TAGS_WARM
} from '../../../resources/enums';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IcreateLead } from '../../../models/lead/ILead';
import { startCase } from 'lodash';
import SelectMenu from '../../../components/form-control/SelectMenu';
import {
  getFormattedPhoneNumber,
  replaceSpacesWithUnderscores
} from '../../../utils/miscFunctions';
import DatePickerInput from '../../../components/form-control/DatePickerInput';
import moment from 'moment';
import { INPUT_DATE_FORMAT } from '../../../resources/constants';
import useRoute from '../../../hooks/routesHook';
import BackButton from '../../../components/BackButton';
import InputSelectMenu from '../../../components/form-control/InputSelectMenu';

const LeadStatus = Object.values(LEAD_STATUS).map((value) => startCase(value));
const LeadSource = Object.values(LEAD_SOURCE).map((value) => startCase(value));
const InsuranceType = Object.values(INSURANCE_TYPE).map((value) => startCase(value));
const GenderList = Object.values(GENDER).map((value) => startCase(value));
const InsurersList = Object.values(CAR_INSURERS_LIST).map((value) => startCase(value)); 


function listTags(status: string) {
  const [leadTagsList, setLeadTagsList] = useState<string[]>([]);
useEffect(() => {
  let tagsFromEnum: string[] = [];
  if (status === 'COLD') {
    tagsFromEnum = Object.values(LEAD_TAGS_COLD).map((value) => startCase(value));
  } else if (status === 'WARM') {
    tagsFromEnum = Object.values(LEAD_TAGS_WARM).map((value) => startCase(value));
  } else if (status === 'CLOSED') {
    tagsFromEnum = Object.values(LEAD_TAGS_CLOSED).map((value) => startCase(value));
  }else if (status === 'PAID') {
    tagsFromEnum = Object.values(LEAD_TAGS_CLOSED).map((value) => startCase(value));
  }
 
  setLeadTagsList(tagsFromEnum);
  
}, [status,leadTagsList]);

return leadTagsList;
}



const CreateLeads = () => {
  const [submitting, setSubmitting] = useState(false);
  const { getDashboardBase } = useRoute();

  return (
    <Formik
      initialValues={{
        firstname: '',
        lastname: '',
        phone: '',
        email: '',
        currentInsurer: '',
        gender: '',
        insuranceType: '',
        expiryDate: null,
        leadStatus: '',
        leadSource: '',
        registrationNumber: '',
        description: '',
        tag:''
      }}
      validationSchema={Yup.object().shape({
        firstname: Yup.string().required('First name is required'),
        lastname: Yup.string().required('Last name is required.'),
        leadStatus: Yup.string().required('Signup stage is required.'),
        email: Yup.string().email('Invalid email address'),
        phone: Yup.string()
          .trim()
          .matches(/^[0-9]+$/, 'Must be only digits')
          .min(10, 'Must be exactly 10 digits')
          .max(10, 'Must be exactly 10 digits')
          .required('Phone number is required.'),
        gender: Yup.string().required('Gender is required.')
      })}
      onSubmit={(values, { resetForm }) => {
        const payload: IcreateLead = {
          ...values,
          phone: getFormattedPhoneNumber(values.phone),
          leadSource: replaceSpacesWithUnderscores(values.leadSource),
          expiryDate: values.expiryDate ? moment(values.expiryDate).format(INPUT_DATE_FORMAT) : '',
          tag: values.tag ? values.tag : 'N/A'
        };

        setSubmitting(true);
        createLeads(payload)
          .then((response: any) => {
            toast.success(response.message ?? 'Signup created successfully', {
              autoClose: 5000
            });
            resetForm();
          })
          .catch((error: any) => {
            toast.error(error.description, {
              autoClose: 5000
            });
          })
          .finally(() => {
            setSubmitting(false);
          });
      }}>
      {({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => (
        <form className="md:px-[45px] py-[30px] bg-white min-h-full" onSubmit={handleSubmit}>
          <div className="flex items-center gap-8 mb-[36px] px-3">
            <Link to={`${getDashboardBase()}/leads`}>
              <BackButton />
            </Link>
            <h1 className="text-[32px]">Add Signup</h1>
          </div>
          <div className="md:py-10 grid grid-cols-1 md:gap-8 items-center md:grid-cols-3 px-3">
            <div>
              <Input
                type="text"
                id="firstname"
                name="firstname"
                placeholder="Eg: Francis"
                onChange={handleChange}
                onBlur={handleChange}
                value={values.firstname}
                label="First Name"
                required
                error={touched.firstname ? errors.firstname : undefined}
                helperText={touched.firstname ? errors.firstname : undefined}
              />
            </div>
            <div>
              <Input
                type="text"
                id="lastname"
                name="lastname"
                onChange={handleChange}
                onBlur={handleChange}
                value={values.lastname}
                label="Last Name"
                placeholder="Eg: Duah"
                required
                error={touched.lastname ? errors.lastname : undefined}
                helperText={touched.lastname ? errors.lastname : undefined}
              />
            </div>
            <div>
              <Input
                type="email"
                id="email"
                name="email"
                label="Email Address"
                placeholder="Email"
                onChange={handleChange}
                onBlur={handleChange}
                value={values.email}
                error={touched.email ? errors.email : undefined}
                helperText={touched.email ? errors.email : undefined}
              />
            </div>
            <div>
              <Input
                type="tel"
                id="phone"
                name="phone"
                onChange={handleChange}
                onBlur={handleChange}
                value={values.phone}
                label="Phone"
                placeholder="Eg: 0549237261"
                required
                error={touched.phone ? errors.phone : undefined}
                helperText={touched.phone ? errors.phone : undefined}
              />
            </div>
            <div>
              <SelectMenu
                onChange={(option: string | null) => setFieldValue('gender', option)}
                listSelectedValue={values.gender}
                name="gender"
                label="Gender"
                list={GenderList}
                required
                error={touched.gender ? errors.gender : undefined}
                helperText={touched.gender ? errors.gender : undefined}
              />
            </div>
            <div>
              <Input
                type="text"
                name="registrationNumber"
                id="registrationNumber"
                onChange={handleChange}
                onBlur={handleChange}
                value={values.registrationNumber}
                label="Registration Number "
                placeholder="Registration Number"
              />
            </div>
            <div>
              <SelectMenu
                onChange={(option: string | null) => setFieldValue('leadStatus', option)}
                listSelectedValue={values.leadStatus}
                name="leadStatus"
                label="Stage"
                list={LeadStatus}
                required
                error={touched.leadStatus ? errors.leadStatus : undefined}
                helperText={touched.leadStatus ? errors.leadStatus : undefined}
              />
            </div>
            <div>
              <SelectMenu
                onChange={(option: string | null) => setFieldValue('tag', option)}
                listSelectedValue={values.tag}
                name="tag"
                label="Tag"
                list={listTags(values.leadStatus)}
                required
                error={touched.tag ? errors.tag : undefined}
                helperText={touched.tag ? errors.tag : undefined}
              />
            </div>
            <div>
              <SelectMenu
                onChange={(option: string | null) => setFieldValue('leadSource', option)}
                listSelectedValue={values.leadSource}
                name="leadSource"
                label="Source"
                search={true}
                list={LeadSource}
                sort={true}
                error={touched.leadSource ? errors.leadSource : undefined}
                helperText={touched.leadSource ? errors.leadSource : undefined}
              />
            </div>
            <div>
              <InputSelectMenu
                onChange={(option: string | null) => setFieldValue('currentInsurer', option)}
                listSelectedValue={values.currentInsurer}
                search={true}
                sort={true}
                name="currentInsurer"
                label="Current Insurer"
                list={InsurersList}
                error={touched.currentInsurer ? errors.currentInsurer : undefined}
                helperText={touched.currentInsurer ? errors.currentInsurer : undefined}
              />
            </div>
            <div>
              <SelectMenu
                onChange={(option: string | null) => setFieldValue('insuranceType', option)}
                listSelectedValue={values.insuranceType}
                name="insuranceType"
                label="Insurance Type"
                list={InsuranceType}
                sort={true}
                error={touched.insuranceType ? errors.insuranceType : undefined}
                helperText={touched.insuranceType ? errors.insuranceType : undefined}
              />
            </div>
            <div>
              <DatePickerInput
                id="expiryDate"
                selected={values.expiryDate}
                onChange={(date) => setFieldValue('expiryDate', date)}
                dateFormat="MM-dd-yyyy"
                label="Expiry Date"
                showYearDropdown
                allowEarlierSelections={false}
              />
            </div>
          </div>

          <div className="px-3">
            <TextArea
              onValueChanged={handleChange}
              name="description"
              id="description"
              value={values.description}
              label="Other Information"
              onFocusOut={() => {}}
            />
          </div>

          <div className="flex flex-col md:flex-row justify-end mt-10">
            <div>
              <Button
                label="Save"
                className="min-w-[125px] flex items-center justify-center h-[45px] px-3 py-1 hover:bg-primary-border rounded"
                bgColor="primary"
                type="submit"
                disabled={submitting}
                loading={submitting}
              />
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default CreateLeads;
