import { ACCESS_RIGHTS, GENDER, TEAM_TYPES } from '../resources/enums';
import { IPolicyDocument } from '../resources/interfaces';
import { IUserAccount } from './UserAccount';
import { ITeam } from './team/ITeam';
import { Team } from './team/Team';

export class User {
  id: string;
  firstName: string;
  lastName: string;
  fullName?: string;
  email: string;
  profileImage?: string;
  jobTitle?: string;
  username?: string;
  gender?: GENDER | undefined;
  phone?: string;
  city?: string;
  country?: ICountry;
  accessRight?: ACCESS_RIGHTS | undefined;
  account?: IUserAccount;
  teamName?: string;
  team: Team | undefined;
  documents?: IPolicyDocument[];

  constructor(IUserModel: IUser) {
    this.id = IUserModel.id;
    this.firstName = IUserModel.firstName ?? '';
    this.lastName = IUserModel.lastName ?? '';
    this.fullName = IUserModel.fullName ?? `${this.firstName} ${this.lastName}`;
    this.email = IUserModel.email ?? '';
    this.profileImage = IUserModel.profileImage ?? '';
    this.jobTitle = IUserModel.jobTitle ?? '';
    this.username = IUserModel.username ?? '';
    this.gender = IUserModel.gender;
    this.phone = IUserModel.phone ?? '';
    this.city = IUserModel.city ?? '';
    this.country = IUserModel.country;
    this.accessRight = IUserModel?.accessRight;
    this.teamName = IUserModel.teamName;
    this.team = IUserModel.team ? new Team(IUserModel.team) : undefined;
    this.account = IUserModel.account ?? null;
  }

  isAdmin(): boolean {
    return this.accessRight === ACCESS_RIGHTS.ADMINISTRATOR;
  }

  isSuperAdmin(): boolean {
    return this.accessRight === ACCESS_RIGHTS.SUPER_ADMINISTRATOR;
  }

  isAgent(): boolean {
    return this.accessRight === ACCESS_RIGHTS.AGENT;
  }

  isAgencyAdmin(): boolean {
    return this.accessRight === ACCESS_RIGHTS.AGENCY_ADMIN;
  }

  isReviewer(): boolean {
    return this.accessRight === ACCESS_RIGHTS.REVIEWER;
  }

  isAgency(): boolean {
    return (
      this.accessRight === ACCESS_RIGHTS.AGENT || this.accessRight === ACCESS_RIGHTS.AGENCY_ADMIN
    );
  }

  isInsurerAgent(): boolean {
    return this.accessRight === ACCESS_RIGHTS.INSURER_AGENT;
  }
  isInsurerAdmin(): boolean {
    return this.accessRight === ACCESS_RIGHTS.INSURER_ADMIN;
  }

  isInsurerPolicyAdmin(): boolean {
    return this.accessRight === ACCESS_RIGHTS.INSURER_POLICY_ADMIN;
  }

  isInsurerTechAdmin(): boolean {
    return this.accessRight === ACCESS_RIGHTS.INSURER_TECH_ADMIN;
  }

  isInsurerClaimAdmin(): boolean {
    return this.accessRight === ACCESS_RIGHTS.INSURER_CLAIM_ADMIN;
  }

  isInsurerFinanceAdmin(): boolean {
    return this.accessRight === ACCESS_RIGHTS.INSURER_FINANCE_ADMIN;
  }

  isInsurerMarketingAdmin(): boolean {
    return this.accessRight === ACCESS_RIGHTS.INSURER_MARKETING_ADMIN;
  }
  isInsurerUnderwriter(): boolean {
    return this.accessRight === ACCESS_RIGHTS.INSURER_UNDERWRITER;
  }

  isExternalSuper(): boolean {
    return (
      this.accessRight === ACCESS_RIGHTS.AGENCY_ADMIN ||
      this.accessRight === ACCESS_RIGHTS.INSURER_ADMIN
    );
  }

  isInternalAdmin(): boolean {
    return (
      this.accessRight === ACCESS_RIGHTS.ADMINISTRATOR ||
      this.accessRight === ACCESS_RIGHTS.SUPER_ADMINISTRATOR ||
      this.accessRight === ACCESS_RIGHTS.REVIEWER
    );
  }

  isInsurer(): boolean {
    return (
      this.accessRight === ACCESS_RIGHTS.INSURER_POLICY_ADMIN ||
      this.accessRight === ACCESS_RIGHTS.INSURER_ADMIN ||
      this.accessRight === ACCESS_RIGHTS.INSURER_AGENT ||
      this.accessRight === ACCESS_RIGHTS.INSURER_CLAIM_ADMIN ||
      this.accessRight === ACCESS_RIGHTS.INSURER_TECH_ADMIN ||
      this.accessRight === ACCESS_RIGHTS.INSURER_FINANCE_ADMIN
    );
  }

  isRideSharing(): boolean {
    return this.team?.type === TEAM_TYPES.RIDE_SHARING;
  }

  isPartner(): boolean {
    return (
      this.accessRight === ACCESS_RIGHTS.FUEL_MANAGER ||
      this.accessRight === ACCESS_RIGHTS.FUEL_ADMIN ||
      this.accessRight === ACCESS_RIGHTS.FUEL_VENDOR
    );
  }

  isPartnerManager(): boolean {
    return (
      this.accessRight === ACCESS_RIGHTS.FLEET_MANAGER ||
      this.accessRight === ACCESS_RIGHTS.FUEL_MANAGER
    );
  }
  isPartnerAdmin(): boolean {
    return (
      this.accessRight === ACCESS_RIGHTS.FLEET_ADMIN ||
      this.accessRight === ACCESS_RIGHTS.FUEL_ADMIN
    );
  }

  isFleet(): boolean {
    return (
      this.accessRight === ACCESS_RIGHTS.FLEET_MANAGER ||
      this.accessRight === ACCESS_RIGHTS.FLEET_ADMIN
    );
  }

  isFleetManager(): boolean {
    return this.accessRight === ACCESS_RIGHTS.FLEET_MANAGER;
  }

  isFleetAdmin(): boolean {
    return this.accessRight === ACCESS_RIGHTS.FLEET_ADMIN;
  }

  isFuelPartner(): boolean {
    return (
      this.accessRight === ACCESS_RIGHTS.FUEL_MANAGER ||
      this.accessRight === ACCESS_RIGHTS.FUEL_VENDOR ||
      this.accessRight === ACCESS_RIGHTS.FUEL_ADMIN
    );
  }

  isFuelManager(): boolean {
    return this.accessRight === ACCESS_RIGHTS.FUEL_MANAGER;
  }

  isFuelAdmin(): boolean {
    return this.accessRight === ACCESS_RIGHTS.FUEL_ADMIN;
  }

  isFuelVendor(): boolean {
    return this.accessRight === ACCESS_RIGHTS.FUEL_VENDOR;
  }
}

export interface IUser {
  id: string;
  firstName: string;
  lastName: string;
  fullName?: string;
  email: string;
  jobTitle?: string;
  username?: string;
  gender?: GENDER;
  phone?: string;
  city?: string;
  accessRight?: ACCESS_RIGHTS;
  teamName?: string;
  team?: ITeam;
  referralCode?: string;
  account?: any;
  country?: ICountry;
  lastSeen?: string;
  verified?: boolean;
  profileImage?: string;
}

export interface ICountry {
  name: string;
  code: string;
  iso: string;
  language: string;
  currencySymbol: string;
  currencyIso: string;
  flag: string;
}
